<template>
  <div id="app">
    <SimpleModal v-if="modal.visible" :text="modal.text" :type="modal.type" :model="modal.type" />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SimpleModal from '@/components/SimpleModal.vue';

import * as types from '@/store/types';

export default {
  components: { SimpleModal },
  computed: {
    ...mapGetters({
      modal: types.GETTER_MODAL,
    }),
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || this.$t('appTitle');
    },
  },
  mounted() {
    this.$store.commit('@orders/MUTATE_MODAL', {
      confirmed: false,
      text: '',
      visible: false,
      type: 'confirm',
      loading: false,
    });
    const queryString = new URLSearchParams(window.location.search);
    const locale = queryString.get('lang');
    if (queryString && locale) {
      this.$i18n.locale = locale;
    } else {
      this.$i18n.locale = 'pt_BR';
    }
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none !important;
  appearance: none !important;
}

body {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: #f2f3f5;
  overflow: hidden;
}

body,
input,
button {
  font-family: 'Open Sans', sans-serif !important;
}
</style>
