<template>
  <div class="h-100">
    <div class="dataset-ag-grid px-3 h-100" id="grid-wrapper">
      <filter-list :filters="filters" @resetFilters="resetFilters" @remove="removeFilter" />
      <ag-grid-vue
        class="ag-theme-material rounded"
        rowSelection="single"
        :rowDeselection="true"
        :gridOptions="gridOptions"
        :animatedRows="true"
        :columnDefs="columnDefs"
        :rowData="rowData"
        :style="{ width: '100%', height: `calc(100% - 250px)` }"
        :localeText="localeText"
        :modules="modules"
        :defaultColDef="defaultColDef"
        :frameworkComponents="frameworkComponents"
        :loadingOverlayComponent="loadingOverlayComponent"
        :noRowsOverlayComponent="noRowsOverlayComponent"
        :loadingOverlayComponentParams="loadingOverlayComponentParams"
        :components="components"
        @grid-ready="onGridReady"
        @grid-size-changed="onGridSizeChanged"
        @filterChanged="getFilters"
        @selection-changed="setRowSelection"
      ></ag-grid-vue>

      <transition name="fade" mode="out-in">
        <div
          v-if="this.pagination.current_page"
          class="d-flex justify-content-center align-items-center position-relative"
          :class="{ 'mt-4': filters.length > 0, 'mt-5': !filters.length }"
        >
          <uello-pagination
            :color="null"
            :current="currentPage"
            position="left"
            :total="Math.ceil(this.pagination.totalItems / perPage)"
            @click="handlePagination"
          />

          <div
            class="pagination-options position-absolute d-flex justify-content-center align-items-center"
          >
            <b-form-select
              v-model="perPage"
              size="sm"
              class=""
              :options="perPageOptions"
              @change="onLimitChange"
            ></b-form-select>

            <span class="text-dark-darken ml-4"
              ><small
                ><b>{{ $t('general.totalRows', { count: this.pagination.totalItems }) }}</b></small
              ></span
            >
          </div>
        </div>
      </transition>
    </div>
    <modal-email-report :filters="filters" />
  </div>
</template>

<script>
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { UelloPagination } from '@uello/componentello';

import { centralWindowSize } from '@/mixins';
import { formatFilters, getFilters, getReverseFilter } from '@/utils/agGrid';

import FollowAgGridLoadingOverlay from '@/views/pages/components/u-follow/FollowAgGridLoadingOverlay.vue';
import VSelectStatusFilter from '@/views/pages/components/u-follow/VSelectStatusFilter.vue';
import VSelectPartnerChildsFilter from '@/views/pages/components/u-follow/VSelectPartnerChildsFilter.vue';
import DateFilter from '@/views/pages/components/u-follow/DateFilter.vue';
import TextFilter from '@/views/pages/components/u-follow/TextFilter.vue';
import NoRowsOverlay from './DatasetAggridNoRowsOverlay.vue';

export default {
  name: 'dataset-ag-grid',
  mixins: [centralWindowSize],
  props: {
    setData: {
      type: Function,
      required: true,
    },
    defs: {
      type: Array,
      required: true,
    },
    rowData: {
      type: Array,
      default: () => [],
    },
    dataComponents: {
      type: Object,
      default: () => ({}),
    },
    dataFwComponents: {
      type: Object,
      default: () => ({}),
    },
    pagination: {
      type: Object,
      default: () => ({}),
    },
    loadingOnSelectRow: {
      type: Boolean,
      default: () => false,
    },
  },
  /* eslint-disable vue/no-unused-components */
  components: {
    FollowAgGridLoadingOverlay,
    VSelectStatusFilter,
    VSelectPartnerChildsFilter,
    DateFilter,
    NoRowsOverlay,
    TextFilter,
    UelloPagination,
    FilterList: () => import('@/views/pages/components/u-follow/FilterList.vue'),
    FollowSearchBar: () => import('@/views/pages/components/u-follow/FollowSearchBar.vue'),
    ModalEmailReport: () => import('@/views/pages/components/u-follow/ModalEmailReport.vue'),
  },
  data() {
    return {
      filters: [],
      gridOptions: {
        alwaysShowVerticalScroll: true,
        rowStyle: {
          borderColor: 'transparent',
        },
        getRowStyle(params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'red' };
          }
          return null;
        },
      },
      gridHeight: 400,
      columnDefs: null,
      gridApi: null,
      columnApi: null,
      localeText: null,
      paginationPageSize: 5,
      defaultColDef: {
        // editable: true,
        resizable: true,
        filter: true,
        filterParams: {
          applyButton: true,
          resetButton: true,
        },
      },
      frameworkComponents: null,
      loadingOverlayComponent: null,
      loadingOverlayComponentParams: null,
      noRowsOverlayComponent: null,
      noRowsOverlayComponentParams: null,
      modules: AllCommunityModules,
      currentPage: 1,
      perPage: 300,
      perPageOptions: [
        {
          value: 15,
          text: `15 ${this.$t('form.labels.perPage')}`,
        },
        {
          value: 30,
          text: `30 ${this.$t('form.labels.perPage')}`,
        },
        {
          value: 50,
          text: `50 ${this.$t('form.labels.perPage')}`,
        },
        {
          value: 100,
          text: `100 ${this.$t('form.labels.perPage')}`,
        },
        {
          value: 300,
          text: `300 ${this.$t('form.labels.perPage')}`,
        },
      ],
      rows: 0,
      isFiltered: false,
    };
  },
  beforeMount() {
    this.registerComponents();
    this.columnDefs = this.defs;
    this.currentPage =
      this.pagination.current_page !== undefined ? this.pagination.current_page : this.currentPage;
    this.perPage = this.pagination.per_page !== undefined ? this.pagination.per_page : this.perPage;
    this.rows = this.pagination.totalItems !== undefined ? this.pagination.totalItems : this.rows;
    this.gridOptions = {
      context: {
        componentParent: this,
      },
    };
    this.localeText = this.$t('agGrid.localeText');
    this.components = {
      FollowAgGridLoadingOverlay,
      NoRowsOverlay,
      TextFilter,
      ...this.dataComponents,
    };
    this.frameworkComponents = {
      customLoadingOverlay: 'FollowAgGridLoadingOverlay',
      customNoRowsOverlayComponent: 'NoRowsOverlay',
      dateFilter: 'DateFilter',
      textFilter: 'TextFilter',
      ...this.dataFwComponents,
    };
    this.loadingOverlayComponent = 'customLoadingOverlay';
    this.noRowsOverlayComponent = 'customNoRowsOverlayComponent';
  },
  mounted() {
    setTimeout(() => {
      if (!this.rowData.length) {
        this.gridApi.hideOverlay();
        this.gridApi.showNoRowsOverlay();
      }
    }, 4000);
  },
  watch: {
    rowData(value) {
      this.gridApi.hideOverlay();

      if (!value.length) {
        this.gridApi.showNoRowsOverlay();
      }
    },
    getError(value) {
      if (value === true) {
        this.columnApi.autoSizeColumns();
        this.gridApi.hideOverlay();
      }
    },
    getPage(value) {
      if (value) {
        this.currentPage = value;
      }
    },
  },
  methods: {
    registerComponents() {
      if (this.dataComponents) {
        Object.entries(this.dataComponents).forEach(([key, value]) => {
          this.$options.components[key] = value;
        });
      }
    },
    setTour() {
      this.$tours['follow-tour'].start();
    },
    setLoading(filter) {
      this.gridApi.showLoadingOverlay();
      this.filters = [filter];
    },
    onLimitChange(limit) {
      this.gridApi.showLoadingOverlay();

      this.$emit('on-paginate', this.currentPage, this.perPage);
      const filters = this.filters.length > 0 ? formatFilters(this.filters) : null;

      this.setData({ filters, defs: this.defs, rowsLimit: limit });
    },
    setHeight() {
      document.querySelector(
        '.ag-theme-material.rounded[animatedrows="true"]'
      ).style.height = `${this.gridHeight}px`;
    },
    setRowSelection() {
      const [row] = this.gridApi.getSelectedRows();

      if (row) {
        if (this.loadingOnSelectRow) {
          this.gridApi.showLoadingOverlay();
        }
        this.$emit('on-selected-row', row);
      }
    },
    onGridReady({ api, columnApi }) {
      this.gridApi = api;
      this.columnApi = columnApi;
      this.gridApi.showLoadingOverlay();
      api.sizeColumnsToFit();
    },
    onGridSizeChanged(params) {
      params.api.sizeColumnsToFit();
    },
    handleExport() {
      this.gridApi.exportDataAsCsv();
    },
    handlePagination(page) {
      if (this.gridApi) {
        this.gridApi.showLoadingOverlay();
      }

      this.$emit('on-paginate', page, this.perPage);
      const filters = this.filters.length > 0 ? formatFilters(this.filters) : null;
      this.setData({ filters, defs: this.defs });
    },
    resetFilters() {
      if (this.gridApi.getFilterInstance('state')) {
        this.gridApi.getFilterInstance('state').setModel('@reset');
      }

      if (this.gridApi.getFilterInstance('company-display_name')) {
        this.gridApi.getFilterInstance('company-display_name').setModel('@reset');
      }

      this.gridApi.setFilterModel(null);
      this.filters = [];

      this.gridApi.showLoadingOverlay();
      this.$emit('on-change-filter', this.filters);
    },
    getFilters(event) {
      if (Object.keys(event.api.filterManager.allFilters).length) {
        this.filters = getFilters(
          event,
          this.filters.filter(f => f.value !== null && f.value.length)
        );

        this.gridApi.showLoadingOverlay();
        // const filters = this.filters.length > 0 ? formatFilters(this.filters) : null;
        this.filtered = true;

        this.$emit('on-change-filter', this.filters);
      }
    },
    removeFilter(field) {
      this.gridApi.showLoadingOverlay();
      this.filters = this.filters.filter(f => f.field !== field);

      if (this.gridApi.getFilterInstance(getReverseFilter(field))) {
        this.gridApi
          .getFilterInstance(getReverseFilter(field))
          .setModel(field === 'status' || field === 'partner' ? '@reset' : null);
      } else {
        this.gridApi
          .getFilterInstance(field)
          .setModel(field === 'status' || field === 'partner' ? '@reset' : null);
      }

      // this.setOrders({ filters: this.filters, defs: this.getOrders.columnDefs });
    },
  },
};
</script>

<style lang="scss">
.dataset-ag-grid {
  span[ref='eMenu'] {
    opacity: 1 !important;
  }

  .pagination-options {
    right: 0;
    transform: translateY(-5px);

    span,
    select {
      display: block;
      min-width: 160px;
    }
  }

  .ag-theme-material .ag-row {
    cursor: pointer;
  }

  .ag-theme-material .ag-input-wrapper input:not([type]):focus,
  .ag-theme-material .ag-input-wrapper input[type='text']:focus,
  .ag-theme-material .ag-input-wrapper input[type='number']:focus,
  .ag-theme-material .ag-input-wrapper input[type='tel']:focus,
  .ag-theme-material .ag-input-wrapper input[type='date']:focus,
  .ag-theme-material .ag-input-wrapper input[type='datetime-local']:focus {
    border-color: var(--primary);
  }

  .ag-theme-material .ag-row:not(.ag-row-first .ag-row-selected) {
    border: 0;

    &:nth-child(even):not(.ag-row-selected) {
      background: rgba(0, 0, 0, 0.03);
    }
  }

  .ag-theme-material .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-theme-material .ag-ltr .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell,
  .ag-theme-material .ag-ltr .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle,
  .ag-theme-material .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-theme-material .ag-rtl .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell,
  .ag-theme-material .ag-rtl .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle {
    border: 0;
  }

  .ag-theme-material .ag-filter .ag-filter-apply-panel {
    display: flex;
    justify-content: space-between;
    button {
      color: #fff;
      font-weight: bold;
      border: 1px solid var(--primary);
      padding: 2px 6px;
      border-radius: 0.25rem;
      transition: all 200ms ease;
      margin: 0;
      background-color: var(--primary);

      &:nth-child(3n) {
        margin-left: 1rem;
      }

      &:hover {
        background-color: #fff;
        color: var(--primary);
      }
    }
  }

  .ag-theme-material .ag-row-selected {
    background-color: rgba(0, 0, 0, 0.15);
    /* background-color: var(--primary);
    color: #fff; */
  }

  .ag-theme-material .ag-icon {
    color: #fff;
    background-color: var(--primary);
    border-radius: 0.25rem;
    padding: 2px;
  }

  .ag-header-cell-label .ag-header-cell-text {
    font-weight: bold;
    text-transform: uppercase;
  }

  .ag-root.ag-layout-normal {
    border-radius: 0.25rem;
  }
  .ag-root-wrapper-body {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .data-grid {
    width: 100%;
    height: 500px;
  }
}
</style>
