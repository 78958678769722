import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { localize } from 'vee-validate';

async function loadValidationMessages(code) {
  let codeFormated = code;

  if (code === 'en_US') {
    codeFormated = 'en';
  }

  if (code === 'es_ES') {
    codeFormated = 'es';
  }

  const messages = await import(`vee-validate/dist/locale/${codeFormated}.json`).then(locale => {
    localize(codeFormated, locale);

    return { code: locale.code, messages: locale.messages };
  });

  return messages;
}

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(async key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
      messages[locale].validations = await loadValidationMessages(locale);
    }
  });
  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'pt_BR',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en_US',
  messages: loadLocaleMessages(),
});
