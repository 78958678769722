import Vue from 'vue';

import Icon from './components/Icon.vue';
import CloseButton from './components/CloseButton.vue';
import FormCreator from './components/FormCreator.vue';
import ProgressBar from './components/ProgressBar.vue';
import LateralEditFile from './components/LateralEditFile.vue';
import LateralSlider from './components/LateralSlider.vue';
import DatasetAggrid from './components/DatasetAggrid.vue';
import Stepper from './components/Stepper.vue';
import MovideskModal from './components/MovideskTicketFormModal.vue';

Vue.component('icon', Icon);
Vue.component('close-button', CloseButton);
Vue.component('form-creator', FormCreator);
Vue.component('progress-bar', ProgressBar);
Vue.component('lateral-edit-file', LateralEditFile);
Vue.component('lateral-slider', LateralSlider);
Vue.component('dataset-ag-grid', DatasetAggrid);
Vue.component('stepper-component', Stepper);
Vue.component('movidesk-modal', MovideskModal);
