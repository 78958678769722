<template>
  <div class="date-filter p-2">
    <v-date-picker
      v-model="range"
      mode="range"
      locale="pt-BR"
      color="gray"
      is-inline
      :max-date="new Date()"
      :columns="$screens({ default: 1, lg: 2 })"
    />

    <div
      class="ag-filter-apply-panel p-0 mt-4 d-flex justify-content-between align-items-center"
      ref="eButtonsPanel"
    >
      <uello-button @click="resetFilter" :text="$t('agGrid.localeText.resetFilter')" />
      <uello-button @click="setFilter" :text="$t('agGrid.localeText.applyFilter')" />
    </div>
  </div>
</template>

<script>
import { UelloButton } from '@uello/componentello';
// eslint-disable-next-line
import { format, parseISO } from 'date-fns';

export default {
  name: 'date-filter',
  components: {
    UelloButton,
  },
  data() {
    return {
      range: {
        start: new Date(),
        end: new Date(),
      },
      date: {
        dateFrom: null,
        dateTo: null,
      },
    };
  },
  created() {
    this.valueGetter = this.params.valueGetter;
  },
  methods: {
    setFilter() {
      const { start, end } = this.range;

      this.date = {
        dateFrom: start,
        dateTo: end,
      };
    },
    resetFilter() {
      this.date = {
        dateFrom: null,
        dateTo: null,
      };

      this.setModel(null);
    },
    getModel() {
      const { dateFrom, dateTo } = this.date;
      return {
        filterType: 'date',
        dateFrom: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : null,
        dateTo: dateTo ? format(dateTo, 'yyyy-MM-dd') : null,
      };
    },
    setModel(model) {
      if (model) {
        this.date = model.date;
      } else {
        this.date = {
          dateFrom: null,
          dateTo: null,
        };

        this.range.start = new Date();
        this.range.end = new Date();
      }
    },
    isFilterActive() {
      return this.date.dateFrom !== null && this.date.dateTo !== null;
    },
    doesFilterPass(params) {
      const { dateFrom, dateTo } = this.date;

      if (!dateFrom && !dateTo) {
        return false;
      }

      let [value] = this.valueGetter(params.node).split(' ');
      const [day, month, year] = value.split('/');
      value = parseISO(`${year}-${month}-${day}`);

      return value >= dateFrom && value <= dateTo;
    },
  },
  watch: {
    date(val, oldVal) {
      if (val.dateFrom !== oldVal.dateFrom) {
        this.params.filterChangedCallback();
      }
    },
  },
};
</script>

<style lang="scss">
.date-filter {
  div[data-v-536fa780] {
    border-radius: 0.25rem;
  }
}
</style>
