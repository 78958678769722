<template>
  <transition name="fade" mode="out-in">
    <div class="lateral-edit-file position-fixed" v-if="visible">
      <transition name="fade" mode="out-in">
        <div class="bg-overlay" @click="() => toggleVisible()" v-if="lazyVisible"></div>
      </transition>
      <transition name="sidebar-slide-right" mode="out-in">
        <div class="form-container position-absolute bg-white h-100" v-if="lazyVisible">
          <header class="bg-primary px-2 d-flex justify-content-between align-items-center">
            <close-button
              :handleClick="() => toggleVisible()"
              :style="{ order: titleFirst ? 2 : 1 }"
            />
            <p class="text-white m-0" :style="{ order: titleFirst ? 1 : 2 }">
              <b>Editar arquivo</b>
            </p>
          </header>
          <div class="tabs-containner h-100">
            <b-tabs fill active-nav-item-class="tab-active" no-nav-style>
              <b-tab
                v-for="(option, index) in getTabs"
                :ref="option"
                :id="option"
                :key="`tab-option-${index}`"
                :title="$t(`pages.request.${option}`)"
                title-link-class="text-dark-darken font-weight-bold small-text"
              >
                <form-creator
                  :actionButtons="actionButtons"
                  :data="fileData[option]"
                  :errors="getErrors"
                  :index="fileData.index"
                  dict
                  defaultTitle
                  editable
                  onChange
                  @submit="value => changeFile(value, option)"
                  @close="toggleVisible"
                  @errorRemove="removeError"
                  @change="data => setFormValue(option, data)"
                ></form-creator>
                <form-creator
                  v-if="option === 'addressData' && fileData.pickupAddressData"
                  :actionButtons="actionButtons"
                  :data="fileData.pickupAddressData"
                  :errors="getErrors"
                  :index="fileData.index"
                  dict
                  defaultTitle
                  editable
                  onChange
                  @submit="value => changeFile(value, 'pickupAddressData')"
                  @close="toggleVisible"
                  @errorRemove="removeError"
                  @change="data => setFormValue('pickupAddressData', data)"
                ></form-creator>
                <!-- @submit="value => changeFile(value, option)" -->
              </b-tab>
            </b-tabs>
            <div class="d-flex justify-content-center align-items-center mt-3">
              <uello-button
                color="red"
                class="w-100 mx-3 font-weight-bold"
                :disabled="isSendable"
                @click="handleSubmit"
                size="small"
                :text="$t('form.buttons.sendButton')"
              />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { UelloButton } from '@uello/componentello';
import api from '@/services/api';
import * as types from '@/store/types';

export default {
  name: 'lateral-edit-file',
  props: {
    file: {
      type: Object,
      required: true,
    },
    titleFirst: {
      type: Boolean,
      default: () => true,
    },
    visible: {
      type: Boolean,
      default: () => true,
    },
  },
  components: {
    UelloButton,
  },
  data() {
    return {
      lazyVisible: false,
      errors: {},
      tabOptions: [
        {
          title: 'Tab 1',
          tabContent: {
            name: 'Michelon Souza',
            email: 'michelon@email.com',
            value: 'R$1234,00',
          },
        },
        {
          title: 'Tab 2',
          tabContent: {
            name: 'Michelon Souza',
            email: 'michelon@email.com',
            value: 'R$1234,00',
          },
        },
        {
          title: 'Tab 2',
          tabContent: {
            name: 'Michelon Souza',
            email: 'michelon@email.com',
            value: 'R$1234,00',
          },
        },
      ],
      actionButtons: [
        {
          text: 'Cancelar',
          variant: 'secondary',
          type: 'button',
        },
        {
          text: 'Salvar alterações no arquivo',
          variant: 'primary',
          type: 'submit',
          action: () => this.$emit('submit'),
        },
      ],
      fileData: null,
    };
  },
  beforeMount() {
    this.errors = this.file.errors;
    this.fileData = this.file;
  },
  mounted() {
    window.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.toggleVisible();
      }
    });

    this.toggleVisible(true);
    setTimeout(() => {
      this.plotErrorBullets();
    }, 500);
  },
  destroyed() {
    window.removeEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.toggleVisible();
      }
    });
  },
  computed: {
    ...mapGetters({
      chosenOperation: types.GETTER_CHOSEN_OPERATION,
    }),
    getTabs() {
      return Object.keys(this.file).filter(
        key => key !== 'errors' && key !== 'index' && key !== 'pickupAddressData'
      );
    },
    isSendable() {
      return Object.keys(this.errors).length > 0;
    },
    getErrors() {
      return this.errors;
    },
  },
  methods: {
    async handleSubmit() {
      let file = this.fileRefactory();
      file = {
        ...file,
        weight: parseFloat(file.weight),
        q_vol: parseInt(file.q_vol, 10),
      };
      const data = { file, index: this.file.index };

      const { data: response } = await api.post('/orders/normalize/json', {
        file,
        chosenOperation: this.chosenOperation,
      });

      if (response.data && response.data.failed && response.data.failed.length === 0) {
        this.$emit('changed', data);
        this.$emit('close');

        return;
      }

      if (
        response.data &&
        response.data.failed &&
        response.data.failed[0] &&
        response.data.failed[0].error
      ) {
        this.errors = response.data.failed[0].error;
        this.plotErrorBullets();
      }
    },
    changeFile(value, option) {
      this.file[option] = value;
    },
    fileRefactory() {
      const file = { ...this.file };
      delete file.index;
      delete file.errors;

      const fileFormatted = Object.entries(file)
        .map(([, value]) => value)
        .reduce((a, b) => ({ ...a, ...b }), {});

      return fileFormatted;
    },
    toggleVisible(visible = false) {
      this.lazyVisible = visible;

      if (!visible) {
        setTimeout(() => {
          this.$emit('close');
        }, 300);
      }
    },
    plotErrorBullets() {
      const errors = Object.keys(this.errors);
      const tabs = Object.entries(this.file).filter(([key]) => key !== 'errors' && key !== 'index');

      tabs.forEach(([key, value]) => {
        const tab = document.querySelector(`[aria-controls="${key}"]`);

        if (this.hasErrors(value, errors)) {
          tab.classList.add('position-relative');
          tab.setAttribute('data-errors', 'has-errors');
        } else {
          tab.removeAttribute('data-errors');
        }
      });
    },
    hasErrors(value, errors) {
      const fields = Object.keys(value);
      let has = false;

      fields.forEach(field => {
        const errorExists = errors.includes(field);

        if (errorExists) {
          has = true;
        }
      });

      return has;
    },
    removeError(errors) {
      this.errors = errors;
      this.plotErrorBullets();
    },
    setFormValue(option, data) {
      this.fileData[option] = { ...this.fileData[option], ...data };
    },
  },
};
</script>

<style lang="scss">
[data-errors='has-errors'] {
  position: relative;

  &::before {
    content: '!';
    color: #000;
    font-size: 8px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--warning);
    position: absolute;
    top: 5px;
    right: 15px;
  }
}

.lateral-edit-file {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 15;

  .bg-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
  }

  .form-container {
    width: 450px;
    max-width: 100%;
    /* height: 100vh; */
    max-height: 100vh;
    z-index: 16;
    top: 0;
    right: 0;

    header {
      height: 50px;
    }
  }

  .active.tab-active {
    border-bottom: 3px solid var(--primary) !important;
    font-weight: bold;
    color: var(--primary) !important;
  }

  ul[role='tablist'].nav.nav-tabs {
    border: 0;

    li[role='presentation'].nav-item a {
      border: 0;
    }
  }
}

.sidebar-slide-right-enter-active,
.sidebar-slide-right-leave-active {
  transition: all 300ms ease;
}
.sidebar-slide-right-enter,
.sidebar-slide-right-leave-to {
  transform: translateX(450px);
}
</style>
