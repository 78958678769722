// BASE_MODULE
export const GETTER_STATE = '@base_module/GETTER_STATE';

export const MUTATION_STATE = '@base_module/MUTATION_STATE';

export const ACTION_STATE = '@base_module/ACTION_STATE';

// @auth
export const GETTER_IS_AUTHENTICATED = '@auth/GETTER_IS_AUTHENTICATED';
export const GETTER_AUTH_LOADING = '@auth/GETTER_AUTH_LOADING';
export const GETTER_TOKEN = '@auth/GETTER_TOKEN';
export const GETTER_EXPIRES_IN = '@auth/GETTER_EXPIRES_IN';
export const GETTER_PARTNER_CONFIG = '@auth/GETTER_PARTNER_CONFIG';
export const GETTER_KEY = '@auth/GETTER_KEY';

export const MUTATION_IS_AUTHENTICATED = '@auth/MUTATION_IS_AUTHENTICATED';
export const MUTATION_LOGIN_SUCCESS = '@auth/MUTATION_LOGIN_SUCCESS';
export const MUTATION_LOADING = '@auth/MUTATION_LOADING';
export const MUTATTION_PARTNER_CONFIG = '@auth/MUTATTION_PARTNER_CONFIG';

export const ACTION_IS_AUTHENTICATED = '@auth/ACTION_IS_AUTHENTICATED';
export const ACTION_LOGIN_REQUEST = '@auth/ACTION_LOGIN_REQUEST';
export const ACTION_RESET_DATA = '@auth/ACTION_RESET_DATA';

// @orders
export const GETTER_ORDERS = '@orders/GETTER_ORDERS';
export const GETTER_MODAL = '@orders/GETTER_MODAL';
export const GETTER_ORDER = '@orders/GETTER_ORDER';
export const GETTER_LIMIT = '@orders/GETTER_LIMIT';
export const GETTER_PAGE = '@orders/GETTER_PAGE';
export const GETTER_SELECTED = '@orders/GETTER_SELECTED';
export const GETTER_LOADING_PRINT = '@orders/GETTER_LOADING_PRINT';
export const GETTER_PRINT_LABELS = '@orders/GETTER_PRINT_LABELS';
export const GETTER_PARTNER_CHILDS = '@orders/GETTER_PARTNER_CHILDS';
export const GETTER_ORDERS_ERROR = '@orders/GETTER_ORDERS_ERROR';
export const GETTER_PARTNER_OPERATIONS = '@orders/GETTER_PARTNER_OPERATIONS';
export const GETTER_CHOSEN_OPERATION = '@orders/GETTER_CHOSEN_OPERATION';
export const GETTER_SELECTED_ALL = '@orders/GETTER_SELECTED_ALL';

export const MUTATE_ORDERS = '@orders/MUTATE_ORDERS';
export const MUTATE_ORDER = '@orders/MUTATE_ORDER';
export const MUTATE_LIMIT = '@orders/MUTATE_LIMIT';
export const MUTATE_PAGE = '@orders/MUTATE_PAGE';
export const MUTATE_SELECTED = '@orders/MUTATE_SELECTED';
export const MUTATE_LOADING_PRINT = '@orders/MUTATE_LOADING_PRINT';
export const MUTATE_PRINT_LABELS = '@orders/MUTATE_PRINT_LABELS';
export const MUTATE_PARTNER_CHILDS = '@orders/MUTATE_PARTNER_CHILDS';
export const MUTATE_ORDERS_ERROR = '@orders/MUTATE_ORDERS_ERROR';
export const MUTATE_SET_PARTNER_OPERATIONS = '@orders/MUTATE_SET_PARTNER_OPERATIONS';
export const MUTATE_SET_CHOSEN_OPERATIONS = '@orders/MUTATE_SET_CHOSEN_OPERATIONS';
export const MUTATE_MODAL = '@orders/MUTATE_MODAL';
export const MUTATE_ALL_ORDER_SELECTED = '@orders/MUTATE_ALL_ORDER_SELECTED';
export const MUTATE_SELECTED_ALL = '@orders/MUTATE_SELECTED_ALL';

export const ACTION_ORDERS_REQUEST = '@orders/ACTION_ORDERS_REQUEST';
export const ACTION_ORDER_REQUEST = '@orders/ACTION_ORDER_REQUEST';
export const ACTION_ORDER_LOT_REQUEST = '@orders/ACTION_ORDER_LOT_REQUEST';
export const ACTION_ORDER_SELECT = '@orders/ACTION_ORDER_SELECT';
export const ACTION_TURNOFF_PRINT_LOADING = '@orders/ACTION_TURNOFF_PRINT_LOADING';
export const ACTION_ORDER_LABELS_PRINT = '@orders/ACTION_ORDER_LABELS_PRINT';
export const ACTION_PARTNER_CHILDS = '@orders/ACTION_PARTNER_CHILDS';
export const ACTION_CHOSEN_OPERATIONS = '@orders/ACTION_CHOSEN_OPERATIONS';

// @mapOrders
export const GETTER_MAP_STATUS = '@mapOrders/GETTER_MAP_STATUS';
export const GETTER_MAP_SELECTED_DATE = '@mapOrders/GETTER_MAP_SELECTED_DATE';

export const MUTATE_MAP_STATUS = '@mapOrders/MUTATE_MAP_STATUS';
export const MUTATE_MAP_SELECTED_DATE = '@mapOrders/MUTATE_MAP_SELECTED_DATE';
