<template>
  <div
    class="status-filter p-2 ag-filter d-flex flex-column"
    :class="{ active: open }"
    style="position: relative;"
  >
    <multiselect
      v-model="selected"
      :options="options"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="false"
      label="text"
      track-by="value"
      :searchable="false"
      :preselect-first="false"
      :show-labels="false"
      select-label="Aperte enter para selecionar"
      deselect-label="Aperte enter para remover"
      select-group-label="Aperte enter para selecionar o grupo"
      deselect-group-label="Aperte enter para remover o grupo"
      selected-label="Selecionado"
      :max-height="100"
      :option-height="36"
      placeholder="Selecione uma opção"
    ></multiselect>
    <div
      class="ag-filter-apply-panel p-0 mt-4 d-flex justify-content-between align-items-center"
      ref="eButtonsPanel"
    >
      <UelloButton
        color="marine"
        @click="resetFilter"
        :text="$t('agGrid.localeText.resetFilter')"
      />
      <UelloButton @click="toggle" :text="$t('agGrid.localeText.selectAll')" />
      <UelloButton @click="setFilter" :text="$t('agGrid.localeText.applyFilter')" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import { UelloButton } from '@uello/componentello';
import Multiselect from 'vue-multiselect';
import '../../../../assets/css/multiselect.css';

export default {
  name: 'vue-select-status-filter',
  components: {
    UelloButton,
    Multiselect,
  },
  data() {
    return {
      selected: [],
      text: [],
      valueGetter: null,
      options: [],
      open: false,
    };
  },
  beforeMount() {
    this.setOptions();
  },
  created() {
    this.valueGetter = this.params.valueGetter;
  },
  computed: {
    ...mapGetters({
      allOperations: types.GETTER_PARTNER_OPERATIONS,
    }),
    selectedAll() {
      return this.selected.length === this.options.length;
    },
  },
  methods: {
    setOptions() {
      const newOptions = this.allOperations.map(status => ({
        value: status.value,
        text: status.label,
        operation: status.operation,
      }));

      this.options = [...newOptions];
    },
    setFilter() {
      this.text = this.selected.map(opt => opt.value);
    },
    resetFilter() {
      this.selected = [];
      this.text = [];
      this.setModel('@reset');
    },
    isFilterActive() {
      return (
        this.text !== null && this.text !== undefined && this.text !== '' && !!this.text.length
      );
    },
    doesFilterPass(params) {
      return this.text.find((t, index) => {
        const option = this.getOption(t, this.selected[index].operation);
        return !t || option.text === this.valueGetter(params.node);
      });
    },
    getModel() {
      const operations = [];
      const text = this.text
        ? this.text
            .map((t, index) => {
              operations.push(this.selected[index].operation);
              return this.getOption(t, this.selected[index].operation).text;
            })
            .join(', ')
        : null;

      return {
        value: this.text && this.text.length ? this.text : null,
        text,
        state: true,
        filterType: 'state',
        operations,
      };
    },
    getOption(value, operation = 'delivery') {
      const status = this.options.find(opt => opt.value === value && opt.operation === operation);
      return status;
    },
    setModel(model) {
      if (model !== '@reset' && model !== null) {
        this.text = model.value;
      }

      if (model === '@reset') {
        this.text = [];
        this.selected = [];
      }
    },
    toggle() {
      this.$nextTick(() => {
        this.selected = this.selectedAll ? [] : this.options.slice();
      });
    },
  },
  watch: {
    text(val, oldVal) {
      if (val !== oldVal) {
        this.params.filterChangedCallback();
      }
    },
  },
};
</script>

<style lang="scss">
$vs-dropdown-max-height: 300px;
.status-filter {
  width: 400px;
  // transition: height 200ms ease;
  // max-height: 300px;

  .multiselect__content-wrapper {
    // background-color: red;
    // max-height: 300px;
  }

  // &.active {
  //   height: 400px;
  // }
}
</style>
