import Vue from 'vue';
import { AgGridVue } from '@ag-grid-community/vue';

import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
// import '@ag-grid-community/all-modules/dist/styles/ag-theme-bootstrap.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-material.css';
// import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

import '@/assets/scss/_ag-grid.scss';

Vue.component('ag-grid-vue', AgGridVue);
