<template>
  <div class="partner-child-filter p-2 ag-filter d-flex flex-column" :class="{ active: open }">
    <v-select
      v-model="selected"
      ref="vue-select"
      label="text"
      maxHeight="300px"
      multiple
      :options="getOptions"
      :reduce="opt => opt.value"
      :placeholder="$t('agGrid.filters.selectPartner')"
      :clearable="false"
      :clearSearchOnSelect="false"
      @search:focus="open = true"
      @search:blur="open = false"
    ></v-select>

    <div
      class="ag-filter-apply-panel p-0 mt-4 d-flex justify-content-between align-items-center"
      ref="eButtonsPanel"
    >
      <uello-button @click="resetFilter" :text="$t('agGrid.localeText.resetFilter')" />
      <uello-button @click="setFilter" :text="$t('agGrid.localeText.applyFilter')" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import * as types from '@/store/types';
import { UelloButton } from '@uello/componentello';

export default {
  name: 'vue-select-partner-child-filter',
  components: {
    UelloButton,
  },
  data() {
    return {
      selected: [],
      text: [],
      valueGetter: null,
      options: [],
      open: false,
    };
  },
  created() {
    this.valueGetter = this.params.valueGetter;
  },
  computed: {
    ...mapGetters({
      getOptions: types.GETTER_PARTNER_CHILDS,
    }),
  },
  methods: {
    setFilter() {
      this.text = this.selected;
    },
    resetFilter() {
      this.selected = [];
      this.text = [];
      this.setModel('@reset');
    },
    isFilterActive() {
      return (
        this.text !== null && this.text !== undefined && this.text !== '' && !!this.text.length
      );
    },
    doesFilterPass(params) {
      return this.text.find(t => {
        const option = this.getOption(t);
        const test =
          !t ||
          option.text
            .toLowerCase()
            .split(' ')
            .every(filterWord => {
              return (
                this.valueGetter(params.node)
                  .toString()
                  .toLowerCase()
                  .indexOf(filterWord) >= 0
              );
            });

        return test;
      });
    },
    getModel() {
      const text = this.text ? this.text.map(t => this.getOption(t).text).join(', ') : null;
      return {
        value: this.text && this.text.length ? this.text : null,
        text,
        state: true,
        filterType: 'state',
      };
    },
    getOption(value) {
      return this.getOptions.find(opt => opt.value === value);
    },
    setModel(model) {
      if (model !== '@reset' && model !== null) {
        this.text = model.value;
      }

      if (model === '@reset') {
        this.text = [];
        this.selected = [];
      }
    },
  },
  watch: {
    text(val, oldVal) {
      if (val !== oldVal) {
        this.params.filterChangedCallback();
      }
    },
  },
};
</script>

<style lang="scss">
$vs-dropdown-max-height: 300px;
.partner-child-filter {
  width: 250px;
  transition: height 200ms ease;

  .vs__dropdown-menu {
    max-height: 300px;
  }

  &.active {
    height: 400px;
  }
}
</style>
