export const movidesk = {
  data() {
    return {
      movideskIsOpen: false,
      movideskHasButton: true,
      movideskPrefill: undefined,
      movideskOrder: undefined,
    };
  },
  methods: {
    openMovidesk() {
      this.movideskIsOpen = true;
    },
    closeMovidesk() {
      this.movideskIsOpen = false;
    },
  },
};
