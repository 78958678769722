import { AES, enc } from 'crypto-js';

const secretKey = '@uello:portal';
const prefix = process.env.VUE_APP_LOCAL_STORAGE_PREFIX || '@uello:portal';

export const database = () => {
  /**
   * @var {Database} db - variable is a instance of Database class for inplements WEBSQL
   */
  const db = window.openDatabase(`${prefix}:database`, '1.0', 'Meritt Database', 11111111);

  if (!db) {
    throw new Error('No Database :(');
  }

  return {
    async createTable(tableName, columns) {
      return new Promise((resolve, reject) => {
        db.transaction(transaction => {
          transaction.executeSql(
            `CREATE TABLE IF NOT EXISTS ${tableName} (${columns.join(', ')})`,
            [],
            () => resolve(),
            (_, error) => {
              reject(error);

              return false;
            }
          );
        });
      });
    },
    async dropTable(tableName) {
      return new Promise((resolve, reject) => {
        db.transaction(transaction => {
          transaction.executeSql(
            `DROP TABLE ${tableName}`,
            [],
            () => resolve(),
            (_, error) => {
              reject(error);

              return false;
            }
          );
        });
      });
    },
    async save(tableName, row) {
      const [result] = await Promise.all([
        new Promise((resolve, reject) => {
          const rowKeys = Object.keys(row);
          const rowValues = Object.values(row);

          db.transaction(transaction => {
            transaction.executeSql(
              `INSERT INTO ${tableName} (${rowKeys.join(', ')}) values(${rowKeys
                .map(() => '?')
                .join(', ')})`,
              rowValues,
              (_, resultSet) => resolve(Object.values(resultSet.rows)),
              (_, error) => {
                reject(error);

                return false;
              }
            );
          });
        }),
      ]);

      return result;
    },
    async findAll(tableName) {
      const [result] = await Promise.all([
        new Promise((resolve, reject) => {
          db.transaction(transaction => {
            transaction.executeSql(
              `SELECT * FROM ${tableName}`,
              [],
              (_, resultSet) => resolve(Object.values(resultSet.rows)),
              (_, error) => {
                reject(error);

                return false;
              }
            );
          });
        }),
      ]);

      return result;
    },
    async find(tableName, where) {
      const searchKeys = Object.keys(where).map(
        (key, index, arr) => `${key} = ? ${index < arr.length - 1 ? 'AND' : ''}`
      );
      const searchValues = Object.values(where);

      const [result] = await Promise.all([
        new Promise((resolve, reject) => {
          db.transaction(transaction => {
            transaction.executeSql(
              `SELECT * FROM ${tableName} WHERE ${searchKeys.join(' ')}`,
              searchValues,
              (_, resultSet) => resolve(Object.values(resultSet.rows)),
              (_, error) => {
                reject(error);

                return false;
              }
            );
          });
        }),
      ]);

      return result;
    },
    async delete(tableName, id) {
      return new Promise((resolve, reject) => {
        db.transaction(transaction => {
          transaction.executeSql(
            `DELETE * FROM ${tableName} WHERE id = ?`,
            [id],
            () => resolve(),
            (_, error) => {
              reject(error);

              return false;
            }
          );
        });
      });
    },
  };
};

/**
 * Object with 2 functions for `safeStorage` in `localStorage`
 */
export const safeStorage = {
  length: localStorage.length,
  /**
   * `setItem` - Is the function to be set `safeItem` in `localStorage`
   * @param {string} key - Is the key of `data` in `localStorage`.
   * @param {any} value - Value to be `encrypted`, the same being a `string` or `object`.
   * @param {string} storage - Storage as save data
   * @return {void} `void`
   * @usage
   * 		setItem('any_key', {key: 'value', another_key: 2})
   * 		setItem('any_key', 'any value')
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setItem(key, value, storage = 'localStorage') {
    const encryptedKey = `${key}@${secretKey}@${localStorage}`;
    const valueToString = typeof value === 'object' ? JSON.stringify(value) : String(value);
    const encryptedValue = AES.encrypt(valueToString, encryptedKey).toString();

    window[storage].setItem(`${prefix}:${key}`, encryptedValue);
  },
  /**
   * `getItem` - Is the faction to be get `safeItem` in `localStorage`
   * @param {string} key - Is the key of `data` in `localStorage`.
   * @param {string} storage - Storage as get data
   * @return {string | any | undefined} - Returns a formatted value when the same is an object or string when not.
   * Returns `undefined` when value not exists.
   * @usage
   * 		getItem('any_key') -> `{key: 'value', another_key: 2}`
   * 		getItem('any_key') -> `'any value'`
   */
  getItem(key, storage = 'localStorage') {
    const item = window[storage].getItem(`${prefix}:${key}`);

    if (item) {
      const decryptionKey = `${key}@${secretKey}@${localStorage}`;
      const decryptedValue = AES.decrypt(item, decryptionKey).toString(enc.Utf8);

      try {
        return JSON.parse(decryptedValue);
      } catch (error) {
        return decryptedValue;
      }
    }

    return undefined;
  },
  /**
   * `removeItem` - Is the faction to be remove `safeItem` in `localStorage`
   * @param {string} key - Is the key of `data` in `storage`.
   * @param {string} storage - Storage as save data
   * @return {void}
   * Returns `void`.
   * @usage
   * 		removeItem('any_key')
   */
  removeItem(key, storage = 'localStorage') {
    window[storage].removeItem(`${prefix}:${key}`);
  },
  clear(storage = 'localStorage') {
    window[storage].clear();
  },
  key(index, storage = 'localStorage') {
    return window[storage].key(index);
  },
};

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-underscore-dangle
  // eslint-disable-next-line no-proto
  window.safeStorage = safeStorage;
}
