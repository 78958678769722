<template>
  <button class="uello-close-button" @click="handleClick" :title="$t('general.closeButton')">
    <icon :icon="icon" :color="color" :size="size" />
  </button>
</template>

<script>
export default {
  name: 'uello-close-button',
  props: {
    handleClick: {
      type: Function,
      default: () => {},
    },
    color: {
      type: String,
      default: () => '#fff',
    },
    size: {
      type: Number,
      default: () => 26,
    },
    icon: {
      type: String,
      default: () => 'close',
    },
  },
};
</script>

<style lang="scss">
.uello-close-button {
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0 !important;
  box-shadow: none;
}
</style>
