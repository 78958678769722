import Vue from 'vue';

Vue.filter('capitalize', value => {
  if (!value) {
    return '';
  }

  const arr = value.toString().split(' ');
  const capitalizedArray = [];

  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalizedArray.push(capitalized);
  });

  return capitalizedArray.join(' ');
});

Vue.filter('maxWords', value => {
  return value
    .split(' ')
    .filter((w, i) => i < 3)
    .join(' ');
});

Vue.filter('monetary', value => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
});

Vue.filter('smallerText', value => {
  const newStr = value ? value.split('').splice(0, 5) : [];
  newStr.push('...');

  return newStr.join('');
});

Vue.filter('upperCase', value => value.toUpperCase());
