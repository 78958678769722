<template>
  <div class="uello-progress-bar w-100">
    <p class="text-center text-dark-darken mb font-weight-bold" v-if="showPercentage">
      {{ $t(`requestItems.showPercentage`, { percentage: progress.toFixed(2) }) }}
    </p>
    <div class="progress-bar bg-transition" :style="{ height: `${height}px` }">
      <div class="progress" :class="`bg-${color}`" :style="{ width: `${progress}%` }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'uello-progress-bar',
  props: {
    progress: {
      type: Number,
      default: () => 0,
    },
    color: {
      type: String,
      default: () => 'primary',
    },
    height: {
      type: Number,
      default: () => 4,
    },
    showPercentage: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>

<style lang="scss">
.uello-progress-bar {
  .progress-bar {
    .progress {
      height: 100%;
    }
  }
}
</style>
