import * as types from '@/store/types';
import api from '@/services/api';
import { safeStorage } from '@/utils';

export default {
  [types.ACTION_IS_AUTHENTICATED]: ({ commit }, isAuthenticated) => {
    commit(types.MUTATION_IS_AUTHENTICATED, isAuthenticated);
  },
  async [types.ACTION_LOGIN_REQUEST]({ commit }, { email, password }) {
    try {
      commit(types.MUTATION_LOADING, true);
      const { data: response } = await api.post('/login', { email, password });
      api.defaults.headers.Authorization = `Bearer ${response.data.access_token}`;

      const { data: config } = await api.get('/partners/config');
      const { data: system } = await api.get('/system/config');

      const newConfig = {
        ...config.data,
        ...system.data,
        operations: config.data.operations.filter(op => op !== null),
      };

      safeStorage.setItem('config', newConfig);
      commit(types.MUTATTION_PARTNER_CONFIG, newConfig);

      this.$app.$toast.success(this.$app.$t('messages.loginSuccess'));

      commit(types.MUTATION_LOGIN_SUCCESS, { data: response.data });
      commit(types.MUTATION_LOADING, false);
      this.$app.$router.push('/app');
    } catch ({ response }) {
      let message = response ? response.data.message : 'Network error';

      if (response.status === 500) {
        message = this.$app.$t('messages.internalError');
      }

      this.$app.$toast.error(message);
      commit(types.MUTATION_LOADING, false);
      commit(types.MUTATION_LOGIN_SUCCESS, { data: null, remove: true });
    }
  },
  [types.ACTION_RESET_DATA]: ({ commit }) => {
    commit(types.MUTATION_LOGIN_SUCCESS, { data: null, remove: true });
  },
};
