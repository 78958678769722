<template>
  <div class="stepper-component w-100">
    <div class="d-flex align-items-center w-100 justify-content-between position-relative px-4">
      <div class="line position-absolute">
        <div
          class="progress m-0 line h-100 bg-primary"
          :style="{ width: `${progressPercentage}%` }"
        ></div>
      </div>
      <div
        v-for="(step, index) in steps"
        :key="`step-item-${index}`"
        class="step d-flex justify-content-between align-items-center flex-column"
      >
        <icon
          :icon="step.icon"
          :color="`var(--${index <= activeIndex || index === 0 ? 'primary' : 'transition'})`"
        />
        <div
          class="bullet my-2"
          :style="{
            borderColor: `var(--${index <= activeIndex || index === 0 ? 'primary' : 'transition'})`,
          }"
        ></div>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-between px-3">
      <small
        v-for="(step, index) in steps"
        :key="`step-text-${index}`"
        :class="`text-${index <= activeIndex || index === 0 ? 'primary' : 'transition'}`"
        >{{ step.text }}</small
      >
    </div>
    <perfect-scrollbar
      class="stepper-scroll-bar pt-3 px-3"
      id="stepper-scroll-bar"
      :style="{ height: `calc(100vh - ${subHeight}px)` }"
    >
      <slot></slot>
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'stepper-component',
  props: {
    activeIndex: {
      type: Number,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    subHeight: {
      type: Number,
      default: () => 300,
    },
  },
  computed: {
    progressPercentage() {
      const fractionalPercentage = 100 / (this.steps.length - 1);
      return fractionalPercentage * this.activeIndex;
    },
  },
};
</script>

<style lang="scss">
.stepper-component {
  .step {
    .bullet {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      z-index: 10;
      background: #fff;
      border-width: 3px;
      border-style: solid;
    }
  }

  .line {
    content: '';
    height: 2px;
    background: var(--transition);
    transform: translateY(14px);
    width: calc(100% - 70px);
    margin-left: 10px;

    .progress {
      transform: translateY(0);
      transition: width 300ms ease;
    }
  }
}
</style>
