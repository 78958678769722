export default {
  allSelected: false,
  orders: {
    columnDefs: [],
    rowData: [],
    total: 0,
  },
  partnerChilds: [],
  order: null,
  loading: false,
  limit: 15,
  page: 1,
  error: false,
  partnerOperations: [],
  selected: [],
  loadingPrint: false,
  printLabels: [],
  chosenOperation: [],
  recoveryModal: {
    model: 'confirm',
    visible: false,
    loading: false,
    confirmed: false,
    text: '',
    type: '',
  },
};
