<template>
  <transition name="fade" mode="out-in">
    <div class="lateral-slider position-fixed" v-if="visible">
      <transition name="fade" mode="out-in">
        <div class="bg-overlay" @click="() => toggleVisible()" v-if="lazyVisible"></div>
      </transition>
      <transition name="sidebar-slide-right" mode="out-in">
        <div
          class="form-container position-absolute bg-white h-100"
          v-if="lazyVisible"
          :style="{ width: `${width}px` }"
        >
          <header class="bg-primary px-2 d-flex justify-content-between align-items-center">
            <close-button
              :handleClick="() => toggleVisible()"
              :style="{ order: titleFirst ? 2 : 1 }"
            />
            <p class="text-white m-0" :style="{ order: titleFirst ? 1 : 2 }">
              <b>{{ title }}</b>
            </p>
          </header>
          <div class="tabs-containner h-100">
            <div class="h-100">
              <perfect-scrollbar :style="{ height: 'calc(100% - 200px)' }" class="p2">
                <slot></slot>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'lateral-slider',
  props: {
    title: {
      type: String,
      required: true,
    },
    titleFirst: {
      type: Boolean,
      default: () => true,
    },
    visible: {
      type: Boolean,
      default: () => true,
    },
    width: {
      type: Number,
      default: () => 400,
    },
  },
  data() {
    return {
      lazyVisible: false,
    };
  },
  mounted() {
    window.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.toggleVisible();
      }
    });

    this.toggleVisible(true);
  },
  destroyed() {
    window.removeEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.toggleVisible();
      }
    });
  },
  methods: {
    toggleVisible(visible = false) {
      this.lazyVisible = visible;

      if (!visible) {
        setTimeout(() => {
          this.$emit('close');
        }, 300);
      }
    },
  },
};
</script>

<style lang="scss">
.lateral-slider {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  font-size: attr(data-count px);

  .bg-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
  }

  .form-container {
    max-width: 100%;
    height: 100vh;
    max-height: calc(100vh - 200px);
    z-index: 16;
    top: 0;
    right: 0;

    header {
      height: 50px;
    }
  }

  .active.tab-active {
    border-bottom: 3px solid var(--primary) !important;
    font-weight: bold;
    color: var(--primary) !important;
  }
}

.sidebar-slide-right-enter-active,
.sidebar-slide-right-leave-active {
  transition: all 300ms ease;
}
.sidebar-slide-right-enter,
.sidebar-slide-right-leave-to {
  transform: translateX(550px);
}
</style>
