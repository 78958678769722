/* eslint-disable import/no-extraneous-dependencies */
import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';

// Plugins
import './plugins/bootstrap-vue';
import './plugins/vue-toastification';
import './plugins/vue-dropzone';
import './plugins/vue-calendar';
import './plugins/vue-clipboard2';
import './plugins/vue-perfect-scrollbar';
import './plugins/vue-tour';
import './plugins/vee-validate';
import './plugins/vue-select';
import './plugins/ag-grid-vue';
import './plugins/flat-picker';
import './plugins/vue-the-mask';
import './plugins/vue-carousel';

// Vue modular tools
import './filters';
import './globalComponents';

// For instance initialize
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
// import acl from './acl';

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
});

store.$app = app;
export default app.$mount('#app');
