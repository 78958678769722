<template>
  <div class="donut__loading" :style="size"></div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'var(--primary)',
    },
    width: {
      type: Number,
      default: 56,
    },
    height: {
      type: Number,
      default: 56,
    },
  },
  computed: {
    size() {
      return {
        borderRightColor: `${this.color}`,
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
};
</script>

<style lang="scss">
@import '../assets/scss/animations';

.follow-loading-overlay {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
}

.donut__loading {
  display: inline-block;
  border-width: 5px;
  border-style: solid;
  border-right-color: var(--primary);
  border-left-color: #cacaca;
  border-top-color: #cacaca;
  border-bottom-color: #cacaca;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  animation: donut-spin 1.2s linear infinite;
}
</style>
