<template>
  <div class="uello-form-creator position-relative">
    <component
      :is="component"
      class="p-3 form-container"
      :class="component === 'perfect-scrollbar' ? 'scroll-container' : 'volume-container px-3'"
    >
      <b-form @submit.prevent="handleSubmit">
        <b-form-group
          v-for="(data, index) in rows"
          :key="`data-input-${type}-${index}`"
          :label="`${getLabel(data[0])}:`"
          :label-for="`input-data-${type}-${index}`"
          size="sm"
          class="mb-4"
        >
          <b-form-input
            size="sm"
            v-model="form[data[0]]"
            :id="`input-data-${type + index}-${index}`"
            :class="!!errorsList[data[0]] ? 'border-danger' : ''"
            :required="data[0] !== 'complement' && data[0] !== 'reference'"
            :disabled="!editable"
            :type="data[0] === 'date' ? 'date' : 'text'"
            @input="e => changeData(e, data[0])"
            @change="() => removeError(data[0])"
          ></b-form-input>
          <b-alert :show="!!errorsList[data[0]]" variant="danger" class="py-1 mt-2">
            <small
              class="text-danger d-block"
              v-for="(error, index) in errorsList[data[0]]"
              :key="`error-${data[0]}-${index}`"
              >{{ error }}</small
            >
          </b-alert>
        </b-form-group>

        <b-row class="mx-0" v-if="data.geolocation && type === 'address'">
          <b-col class="px-0 mb-4" cols="12">
            <uello-buton
              color="red"
              size="small"
              :disabled="!editable"
              class="w-100"
              @click="getGeoLocation"
              :text="$t('form.buttons.getGeoLocation')"
            />
          </b-col>
          <b-col cols="6" class="pl-0"
            ><b-form-group
              :label="`${capitalize('latitude')}:`"
              label-for="input-data-geolocation-latitude"
              size="sm"
              class="mb-4"
            >
              <b-form-input
                size="sm"
                id="input-data-geolocation-latitude"
                v-model="form.geolocation.latitude"
                :disabled="!editable"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6" class="pr-0">
            <b-form-group
              :label="`${capitalize('longitude')}:`"
              label-for="input-data-geolocation-longitude"
              size="sm"
              class="mb-4"
            >
              <b-form-input
                size="sm"
                id="input-data-geolocation-longitude"
                v-model="form.geolocation.longitude"
                :disabled="!editable"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div
          class="d-flex justify-content-between align-items-center mt-5"
          v-if="editable && !onChange"
        >
          <b-button
            size="sm"
            class="font-weight-bold"
            v-for="(button, index) in actionButtons"
            :variant="button.variant"
            :type="button.type || 'button'"
            :key="`action-button-${index}`"
            :title="$t('form.buttons.cancelAlterationsButton')"
            @click="button.action || $emit('close')"
            >{{ button.text }}</b-button
          >
        </div>
      </b-form>
    </component>
    <transition name="fade" mode="out-in">
      <div
        class="loading-overlay position-absolute d-flex justify-content-center align-items-center"
        v-if="loading"
      >
        <Loading :height="60" :width="60" />
      </div>
    </transition>
  </div>
</template>

<script>
import { UelloButton } from '@uello/componentello';
import viacepApi from '@/services/viacep';
import api from '@/services/api';
import { capitalize, safeStorage } from '@/utils';
import Loading from './Loading.vue';

export default {
  name: 'uello-form-creator',
  props: {
    data: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },
    dict: {
      type: Boolean,
      default: () => false,
    },
    defaultTitle: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      default: () => 'id',
    },
    editable: {
      type: Boolean,
      default: () => false,
    },
    allData: {
      type: Boolean,
      default: () => false,
    },
    onChange: {
      type: Boolean,
      default: () => false,
    },
    actionButtons: {
      type: Array,
      default() {
        return [
          {
            text: this.$t('form.buttons.cancelAlterationsButton'),
            variant: 'light',
            type: 'button',
            action: null,
          },
          {
            text: this.$t('form.buttons.saveSlterationsButton'),
            variant: 'primary',
            type: 'submit',
            action: () => this.$emit('submit', this.form),
          },
        ];
      },
    },
    component: {
      type: String,
      default: () => 'perfect-scrollbar',
    },
  },
  components: {
    Loading,
    UelloButton,
  },
  data() {
    return {
      form: {},
      rows: [],
      errorsList: {},
      loading: false,
      dictionary: null,
    };
  },
  beforeMount() {
    this.form = this.data;
    this.dictionary = safeStorage.getItem('config').dict;
    this.errorsList = this.errors || {};

    this.generateRows();
  },
  watch: {
    errors: {
      handler(newValue) {
        this.errorsList = newValue || {};
      },
      deep: true,
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', this.form);
    },
    generateRows() {
      this.form = Object.entries(this.data)
        .map(([key, value]) => [key, value || ''])
        .reduce((a, b) => {
          const [key, value] = b;
          a[key] = value;
          return a;
        }, {});

      let data = Object.entries(this.data).map(([key, value]) => {
        const arr = [key];

        if (value !== null && value !== undefined && value !== '') {
          arr.push(value);
        } else {
          arr.push('');
        }

        return arr;
      });

      data = data.filter(d => !d.includes('geolocation'));

      this.rows = data;
    },
    capitalize(value) {
      return capitalize(this.$t(`orderEditModals.${value}`));
    },
    getLabel(data) {
      let label = '';

      if (!this.dict) {
        // eslint-disable-next-line no-nested-ternary
        label = this.defaultTitle
          ? data
          : this.capitalize(data) === 'Total'
          ? `${this.capitalize(data)} R$`
          : this.capitalize(data);
      } else {
        label = this.dictionary[data] === 'Total' ? 'Total R$' : this.dictionary[data];
      }

      return label;
    },
    updateErrors() {
      this.errorsList = this.errors;
    },
    removeError(key) {
      const errors = { ...this.errors };
      delete errors[key];
      this.errorsList = errors;
      this.$emit('errorRemove', errors);
    },
    changeData(value, data) {
      this.getAddressByPostcode(data, value);

      if (this.onChange) {
        if (this.form.weight) {
          const weight = this.form.weight.replace(',', '.');
          this.form.weight = weight;
        }

        this.$emit('change', this.allData ? this.form : { [data]: value });
      }
    },
    async getAddressByPostcode(inputType, value) {
      if (inputType === 'postcode' && this.type === 'address' && value.length === 8) {
        this.loading = true;

        const { data } = await viacepApi.get(`/${value}/json`);

        if (data.erro) {
          this.$toast.error(this.$t('messages.postcodeFail'));
        } else {
          const {
            cep: postcode,
            logradouro: address,
            bairro: neighborhood,
            localidade: city,
            uf,
          } = data;

          this.form = {
            ...this.form,
            postcode: postcode.replace(/-/g, ''),
            address: address.toUpperCase(),
            neighborhood: neighborhood.toUpperCase(),
            city: city.toUpperCase(),
            uf: uf.toUpperCase(),
          };

          this.$emit('change', this.form);
        }

        this.loading = false;
      }
    },
    async getGeoLocation() {
      this.loading = true;
      const { address, number, neighborhood, city, uf, postcode } = this.form;
      const sendAddress = `${address},${number}-${neighborhood}-${city}-${uf}-${postcode}`;

      try {
        const { data: response } = await api.post('/orders/geolocation', { address: sendAddress });

        this.form = {
          ...this.form,
          geolocation: {
            latitude: String(response.data.LATITUDE),
            longitude: String(response.data.LONGITUDE),
          },
        };

        this.$toast.success(this.$t('messages.getGeolocationSuccess'));
        this.$emit('change', this.form);
      } catch (error) {
        this.$toast.error(this.$t('messages.getGeolocationError'));
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.uello-form-creator {
  .scroll-container {
    height: calc(100vh - 150px);
  }

  .volume-container {
    height: fit-content;
  }

  .form-container {
    label {
      font-size: 0.8rem;
      font-weight: bold;
      color: var(--dark-darken);
    }
  }

  .loading-overlay {
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
