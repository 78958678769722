// eslint-disable-next-line
import * as types from '@/store/types';

import Vue from 'vue';
import VueRouter from 'vue-router';
import api from '@/services/api';
import { safeStorage } from '@/utils';
import store from '@/store';
import i18n from '@/i18n';

function isAuthenticated() {
  const logged = store.getters[types.GETTER_IS_AUTHENTICATED];
  const expiresIn = store.getters[types.GETTER_EXPIRES_IN];
  const config = store.getters[types.GETTER_PARTNER_CONFIG];

  if (config) {
    safeStorage.setItem('config', JSON.stringify(config));
  }

  const isAfter = expiresIn !== null && expiresIn > new Date().getTime();

  return isAfter && logged;
}

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      title: 'Portal do Parceiro | Login',
      rule: 'public',
    },
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next('/app');
      } else {
        next();
      }
    },
  },
  {
    path: '/app',
    redirect: '/app/map',
    component: () => import('@/views/pages/App.vue'),
    children: [
      {
        path: '/app/map',
        component: () => import('@/views/pages/Map.vue'),
      },
      {
        path: '/app/spot-route',
        component: () => import('@/views/pages/SpotRoute.vue'),
      },
      {
        path: '/app/follow',
        component: () => import('@/views/pages/Follow.vue'),
        meta: {
          title: i18n.t('pageTitles.follow'),
          hasIcon: false,
        },
      },
      {
        path: '/app/request',
        component: () => import('@/views/pages/Request.vue'),
        meta: {
          title: i18n.t('pageTitles.request'),
          hasIcon: false,
        },
      },
      {
        path: '/app/favorite-addresses',
        component: () => import('@/views/pages/FavoriteAddresses.vue'),
        meta: {
          title: i18n.t('pageTitles.favoriteAddresses'),
          hasIcon: false,
        },
      },
      {
        path: '/app/report',
        component: () => import('@/views/pages/Report.vue'),
      },
      {
        path: '/app/history',
        component: () => import('../views/pages/History.vue'),
        children: [
          {
            path: '/app/history/orders',
            component: () => import('@/views/pages/HistoryOrders.vue'),
            meta: {
              title: i18n.t('pageTitles.historyOrders'),
              hasIcon: false,
            },
          },
        ],
      },
      {
        path: '/app/users-management',
        component: () => import('@/views/pages/Management.vue'),
        meta: {
          title: i18n.t('pageTitles.usersManagement'),
          hasIcon: true,
          urlLink: process.env.VUE_APP_MOVIDESK_HELP_USERS,
          icon: 'information-outline',
          description: 'Dúvidas',
        },
      },
      {
        path: '/app/finances',
        component: () => import('@/views/pages/Finances.vue'),
        meta: {
          title: i18n.t('pageTitles.finances'),
          hasIcon: false,
        },
      },
      {
        path: '/app/faq',
        component: () => import('@/views/pages/Faq.vue'),
        meta: {
          title: i18n.t('pageTitles.faq'),
          hasIcon: false,
        },
      },
    ],
    beforeEnter: (to, from, next) => {
      if (!isAuthenticated()) {
        store.$app.$toast.error(store.$app.$t('messages.loginExpired'));
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '/404',
    component: () => import('@/views/pages/NotFound404.vue'),
    beforeEnter: (to, from, next) => {
      if (!isAuthenticated()) {
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const waitForRestore = async (to, from, next) => {
  await store.restored;
  if (store.getters[types.GETTER_TOKEN]) {
    api.defaults.headers.Authorization = `Bearer ${store.getters[types.GETTER_TOKEN]}`;
    api.defaults.headers.Language = navigator.language;
  }

  await store.commit(types.MUTATE_ORDER, null);
  next();
};

router.beforeEach((to, from, next) => {
  waitForRestore(to, from, next);
});

export default router;
