/* eslint-disable func-names */
export * from './movidesk';

export const widthMixin = {
  data() {
    return {
      windowWidth: 0,
      windowHeight: 0,
    };
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('resize', this.getWindowWidth);

      this.getWindowWidth({ target: window });
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
  methods: {
    getWindowWidth(e) {
      const { innerWidth, innerHeight } = e.target;
      this.windowWidth = innerWidth;
      this.windowHeight = innerHeight;
    },
  },
  computed: {
    width() {
      return this.windowWidth;
    },
  },
};

export const centralWindowSize = {
  data() {
    return {
      containerWidth: 0,
      containerHeight: 0,
    };
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('resize', this.getContainerWidth);

      this.getContainerWidth();
    });

    this.getContainerWidth();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getContainerWidth);
  },
  methods: {
    getContainerWidth() {
      const container = document.querySelector('.uello-app .route-container');
      this.containerWidth = container.offsetWidth;
      this.containerHeight = container.offsetHeight < 300 ? 650 : container.offsetHeight;
    },
  },
};
