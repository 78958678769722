<template>
  <div class="movidesk-ticket-form-modal position-fixed text-right" :class="{ open: lazyOpen }">
    <transition key="movidesk-ticket-form-modal" name="slide-top-fadeout" mode="out-in">
      <div
        v-if="isOpen"
        class="movidesk-ticket-form-modal-container position-absolute bg-white rounded"
      >
        <div class="position-relative">
          <transition key="movidesk-ticket" name="fade" mode="out-in">
            <div
              class="ticket-overlay position-absolute d-flex align-items-center justify-content-center"
              v-if="loading"
            >
              <icon icon="loading" color="var(--primary)" :size="90" :spin="true" />
            </div>
          </transition>
          <header
            class="ticket-form-header d-flex align-items-center justify-content-senter bg-primary position-relative p-3"
          >
            <uello-button
              class="ticket-close-button d-flex align-items-center justify-content-center rounded position-absolute"
              color="white"
              colorless
              icon="close"
              iconSize="20"
              @click="closeMovidesk"
              :title="$t('general.closeButton')"
            />
            <h3 class="text-white text-center font-weight-bold w-100">
              <svg xmlns="http://www.w3.org/2000/svg" width="246" height="60" viewBox="0 0 246 60" v-bind:svg-inline="''" v-bind:class="'uello-logo'" v-bind:style="'height: 50px;'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#fff"><path d="M216.03 53.357c-12.983 0-23.545-10.51-23.545-23.43 0-12.922 10.562-23.433 23.545-23.433 12.983 0 23.546 10.51 23.546 23.432 0 12.92-10.563 23.431-23.546 23.431m0-53.144c-16.462 0-29.855 13.329-29.855 29.713 0 16.383 13.393 29.712 29.855 29.712 16.464 0 29.857-13.329 29.857-29.712 0-16.384-13.393-29.713-29.857-29.713M97.242 22.569v-5.377H79.456v26.68h17.786v-5.394h-11.3v-5.396h8.903v-5.257h-8.902V22.57zm19.692-6.59v26.68h16.516v-5.515h-10.12V15.979zm35.573 1.213v26.68h16.516v-5.516h-10.12V17.192zM21.548 41.95c.99.985 2.208 1.765 3.621 2.315 1.4.543 2.99.82 4.73.82 1.737 0 3.328-.277 4.73-.82 1.412-.55 2.632-1.328 3.627-2.313.996-.986 1.77-2.17 2.297-3.52.523-1.34.789-2.812.789-4.377v-15.65h-6.458v15.65c0 .853-.119 1.627-.351 2.304-.229.653-.555 1.212-.973 1.66-.42.45-.922.79-1.535 1.04-1.232.505-3.022.507-4.254.001a4.11 4.11 0 01-1.518-1.044 4.781 4.781 0 01-.97-1.674c-.235-.675-.353-1.45-.353-2.303V18.404h-6.457v15.651c0 1.568.265 3.04.789 4.376a10.084 10.084 0 002.286 3.519"/><path d="M29.908 53.357c-12.983 0-23.546-10.51-23.546-23.43 0-12.922 10.563-23.433 23.546-23.433 12.983 0 23.546 10.51 23.546 23.432 0 12.92-10.563 23.431-23.546 23.431m0-53.144C13.444.213.05 13.542.05 29.926c0 16.383 13.393 29.712 29.857 29.712 16.462 0 29.855-13.329 29.855-29.712C59.763 13.542 46.37.213 29.908.213"/></g></svg>
            </h3>
          </header>

          <transition key="movidesk-ticket-status" name="slide-top-fadeout" mode="out-in">
            <b-form
              v-if="!success && !error"
              class="ticket-form px-4 pb-2"
              @submit.prevent="handleSubmit"
            >
              <b-form-input
                id="input-name"
                class="border-0 bg-white"
                v-model="form.name"
                type="text"
                autocomplete="new-username"
                size="sm"
                placeholder="Nome*"
                required
              ></b-form-input>
              <b-form-input
                id="input-email"
                class="border-0 mt-3 bg-white"
                v-model="form.email"
                type="email"
                autocomplete="new-username"
                size="sm"
                placeholder="Email*"
                required
              ></b-form-input>
              <b-form-input
                id="input-phone"
                class="border-0 mt-3 bg-white"
                v-model="form.phone"
                type="text"
                autocomplete="new-username"
                size="sm"
                placeholder="Celular (opcional)"
              ></b-form-input>
              <b-form-textarea
                id="input-description"
                class="border-0 mt-3 bg-white"
                size="sm"
                rows="6"
                no-resize
                v-model="form.description"
                placeholder="Como podemos ajudá-lo?"
                required
              ></b-form-textarea>
              <div class="d-flex align-items-center mt-3">
                <b-button
                  class="w-100 ticket-send-button"
                  type="submit"
                  variant="primary"
                  size="lg"
                >
                  {{ $t('form.buttons.sendButton') }}
                </b-button>
              </div>
            </b-form>

            <div
              v-if="success && !error"
              class="ticket-success bg-white px-4 d-flex align-items-center justify-contente-center"
            >
              <div class="d-flex flex-column align-items-center w-100">
                <icon icon="email-check-outline" :size="80" color="var(--transition)" />
                <p class="text-primary mt-2 font-weight-bold">
                  {{ $t('movidesk.success') }}
                </p>
                <p class="text-primary mt-5 font-weight-bold">
                  {{ $t('movidesk.protocolNumber') }}
                </p>
                <p class="text-muted mt-4 font-weight-bold">N° {{ protocol }}</p>
              </div>
            </div>
            <div
              v-if="error && !success"
              class="ticket-success bg-white px-4 d-flex align-items-center justify-contente-center"
            >
              <div class="d-flex flex-column align-items-center w-100">
                <icon icon="email-remove-outline" :size="80" color="var(--danger)" />
                <p class="text-primary mt-2 font-weight-bold">
                  {{ $t('movidesk.error') }}
                </p>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';

import { getPartner, maxLengthString, safeStorage } from '@/utils';
import { UelloButton } from '@uello/componentello';

export default {
  name: 'movidesk-ticket-form-modal',
  props: {
    order: {
      type: Object,
    },
    isOpen: {
      type: Boolean,
      default: () => false,
    },
    hasButton: {
      type: Boolean,
      default: () => false,
    },
    prefill: {
      type: Object,
    },
  },
  components: {
    UelloButton,
  },
  data() {
    return {
      movideskUrl: process.env.VUE_APP_MOVIDESK_API_URL,
      lazyOpen: false,
      loading: false,
      success: false,
      error: false,
      protocol: '',
      form: {
        name: '',
        email: '',
        phone: '',
        description: '',
      },
      dataPersonStructure: {
        isActive: true,
        personType: 2,
        profileType: 2,
        businessName: '',
        emails: [
          {
            emailType: 'professional',
            email: '',
            isDefault: true,
          },
        ],
      },
      dataSendStructure: {
        type: 2,
        subject: '',
        urgency: 'Média',
        origin: 9,
        tags: ['portal'],
        createdBy: {
          id: 'personId',
        },
        clients: [
          {
            id: 'personId',
            businessName: '',
            email: '',
            phone: '',
            personType: 2,
            profileType: 2,
          },
        ],
        actions: [
          {
            createdBy: 'personId',
            description: '',
            type: 2,
          },
        ],
        customFieldValues: [
          {
            customFieldId: 65581,
            customFieldRuleId: 31721,
            line: 1,
            value: '',
          },
        ],
      },
    };
  },
  created() {
    this.setPreform();
  },
  watch: {
    isOpen(value, oldValue) {
      if (value && value !== oldValue) {
        this.setPreform();

        setTimeout(() => {
          this.lazyOpen = true;
        }, 300);
      }
    },
  },
  methods: {
    async handleSubmit() {
      try {
        this.loading = true;

        const body = {
          movielloClientId: 'uello-cx',
          person: {
            ...this.dataPersonStructure,
            businessName: getPartner(),
            emails: [
              {
                ...this.dataPersonStructure.emails[0],
                email: this.form.email,
              },
            ],
          },
          ticket: this.setFormData(),
        };

        const response = await axios.post(`${this.movideskUrl}`, body, {
          headers: {
            'x-api-key': process.env.VUE_APP_MOVIDESK_API_KEY,
          },
        });

        this.protocol = response.data.protocol;
        this.loading = false;
        this.success = true;
      } catch (error) {
        this.loading = false;
        this.error = true;
        this.success = false;

        setTimeout(() => {
          this.error = false;
        }, 3000);
      }
    },
    openMovidesk() {
      this.setPreform();
      this.$emit('open');
      setTimeout(() => {
        this.lazyOpen = true;
      }, 300);
    },
    setPreform() {
      if (this.prefill) {
        const { email } = safeStorage.getItem('user');

        this.form = {
          name: getPartner(),
          email: email || '',
          phone: '',
        };
      }
    },
    setFormData() {
      const ticket = { ...this.dataSendStructure };
      ticket.subject = maxLengthString(this.form.description, 30);
      ticket.tags = [...ticket.tags, getPartner().toLowerCase()];
      ticket.clients[0] = {
        ...ticket.clients[0],
        email: this.form.email,
        businessName: getPartner(),
        phone: this.form.phone,
      };
      ticket.actions[0] = {
        ...ticket.actions[0],
        description: this.form.description,
      };
      ticket.customFieldValues[0] = {
        ...ticket.customFieldValues[0],
        value: this.order
          ? `https://bko.uello.com.br/services/${this.order.id}/edit`
          : 'https://bko.uello.com.br/services',
      };
      if (this.order) {
        ticket.orderId = this.order.id;
      }

      return ticket;
    },
    closeMovidesk() {
      this.$emit('close');
      this.form = {
        name: '',
        email: '',
        phone: '',
        description: '',
      };
      this.loading = false;
      this.success = false;
      this.error = false;
      setTimeout(() => {
        this.lazyOpen = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss">
.movidesk-ticket-form-modal {
  width: 100%;
  z-index: 100000;
  bottom: 20px;
  right: 20px;

  @media screen and (min-width: 992px) {
    width: fit-content;
  }

  &.open {
    min-width: 360px;
    top: 0;
    left: 0;
    bottom: initial;
    right: initial;
  }

  @media screen and (min-width: 992px) {
    top: initial;
    left: initial;
    bottom: 15px;
    right: 15px;

    &.open {
      top: initial;
      left: initial;
      bottom: 15px;
      right: 15px;
    }
  }

  .modal-open-button {
    transition: all 200ms ease;

    & > span {
      display: none;
    }

    &:hover {
      & > svg {
        margin-right: 0.5rem;
      }
      & > span {
        display: initial;
      }
    }
  }

  .movidesk-ticket-form-modal-container {
    z-index: 100000;
    position: fixed;
    max-width: 100%;
    overflow: hidden;
    box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.1);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;

    @media screen and (min-width: 992px) {
      width: 360px;
      height: fit-content;
      top: initial;
      left: initial;
      bottom: 0;
      right: 0;
    }
  }

  .ticket-overlay {
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }

  .ticket-form-header {
    height: 120px;

    .ticket-close-button {
      width: 34px;
      height: 34px;
      border: 0;
      outline: 0;
      top: 10px;
      right: 10px;
      background: rgba(0, 0, 0, 0.3);
      transition: all 200ms ease;

      &:hover {
        background: rgba(0, 0, 0, 0.5);
      }

      & > span {
        height: 27px;
      }
    }
  }

  .ticket-form {
    transform: translateY(-20px);

    input {
      height: 40px;
    }

    & > input,
    & > textarea {
      box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.1);
      font-size: 16px;
    }

    .ticket-send-button {
      height: 40px;
      font-size: 16px;
    }
  }

  .ticket-success {
    height: 384px;
  }
}
</style>
