<template>
  <div :class="componentClasses">
    <h4 class="text-transition text-uppercase mb-2">{{ $t('agGrid.localeText.noRowsToShow') }}</h4>
    <uello-icon class="stop-icon" icon="emoticon-cry-outline" />
  </div>
</template>

<script>
import { UelloIcon } from '@uello/componentello';

export default {
  components: {
    UelloIcon,
  },
  data() {
    return {
      componentClasses: `dataset-ag-grid-no-rows-overlay bg-light d-flex align-items-center justify-content-center flex-column font-weight-bold`,
    };
  },
};
</script>

<style lang="scss">
.dataset-ag-grid-no-rows-overlay {
  height: 100%;
  width: 100%;

  .stop-icon {
    color: var(--transition);
    font-size: 70px;
    height: 70px;
    width: 70px;
    /* border: 4px solid var(--transition);
    border-radius: 50%; */
  }
}
</style>
