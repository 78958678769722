/* eslint-disable no-nested-ternary */
import i18n from '@/i18n';
import { getObject, safeStorage } from '.';

export const getCellStyle = params => {
  const { statuses } = safeStorage.getItem('config');
  let style = null;
  Object.keys(statuses).forEach(key => {
    const operation = statuses[key];

    Object.keys(operation).forEach(state => {
      if (
        (operation[state].label === params.value && style === null) ||
        params.value === 'danificado'
      ) {
        style = {
          background: params.value === 'danificado' ? '#000' : operation[state].color,
          color: '#fff',
          height: '36px',
          marginTop: '6px',
          marginLeft: '10px',
          display: 'flex',
          alignItems: 'center',
          borderRadius: '0.25rem',
          width: '190px',
        };
      }
    });
  });

  return style;
};

export const getStateLabel = (operationType, state) => {
  const { statuses } = safeStorage.getItem('config');
  return statuses[operationType][state]
    ? statuses[operationType][state].label
    : statuses[operationType].outros.label;
};

export const getColumnWidth = id => {
  switch (id) {
    case 'customer.name':
      return 490;
    case 'items.0.identification':
      return 385;
    case 'invoices.0.number':
    case 'items.length':
      return 130;
    case 'delivered_data.date':
    case 'created_at':
      return 180;
    case 'operation.name':
      return 220;
    case 'shipping_number':
    case 'state':
    case 'agreement_date':
      return 200;
    default:
      return 200;
  }
};

export const getFilter = field => {
  switch (field) {
    case 'created_at':
    case 'delivered_data.date':
    case 'agreement_date':
      return 'dateFilter';
    case 'state':
      return 'vSelectStatusFilter';
    case 'company.display_name':
      return 'vSelectPartnerChildsFilter';
    case 'operation.name':
      return 'vSelectOperationFilter';
    default:
      return 'textFilter';
  }
};

export const isFiltered = field => {
  switch (field) {
    // case 'operation.name':
    case 'items.length':
    case 'items.0.identification':
    case 'invoices.0.number':
      return false;
    default:
      return true;
  }
};

export const defineColumnDefs = () => {
  const config = safeStorage.getItem('config');
  const { show, hide, default: initial } = config.table.header;

  const hideColumns = hide.map(h => h.field);
  let showColumns = [];

  if (show.length) {
    showColumns = show.map(column => ({
      headerName: column.label,
      field: column.field,
      sortable: true,
      filter: isFiltered(column.field) && getFilter(column.field),
      minWidth: getColumnWidth(column.field),
    }));
  }

  const filteredColumns = initial.filter(
    column =>
      !!column.label &&
      column.field !== 'state' &&
      column.field &&
      !hideColumns.includes(column.field)
  );
  const stateColumn = initial.find(column => column.field === 'state');

  let defineColumns = filteredColumns.map(column => ({
    headerName: i18n.t(`agGrid.columnDefs.${column.label.toLowerCase().replaceAll(' ', '_')}`),
    field: column.field.replace(/\./g, '-'),
    sortable: isFiltered(column.field),
    filter: isFiltered(column.field) && getFilter(column.field),
    width: getColumnWidth(column.field),
    minWidth: getColumnWidth(column.field),
    cellStyle: getCellStyle,
    editable: false,
    // minWidth: 150,
  }));

  defineColumns.push({
    headerName: i18n.t(`agGrid.columnDefs.expected_date`),
    field: 'first_agreement_date',
    filter: 'dateFilter',
    editable: false,
    minWidth: 180,
    sortable: true,
    width: 180,
    cellStyle: getCellStyle,
  });

  const state = {
    headerName: stateColumn.label,
    field: 'state',
    filter: getFilter('state'),
    width: getColumnWidth(stateColumn.field),
    minWidth: getColumnWidth(stateColumn.field),
    cellStyle: getCellStyle,
    sortable: true,
    editable: false,
    filterParams: {
      applyButton: true,
      clearButton: true,
    },
    // minWidth: 150,
  };

  defineColumns = [state, ...showColumns, ...defineColumns];

  defineColumns.push({
    headerName: 'Id',
    field: 'id',
    sortable: false,
    filter: false,
    hide: true,
  });
  defineColumns.push({
    headerName: 'Operation Type',
    field: 'operation.type',
    sortable: false,
    filter: false,
    hide: true,
  });

  return defineColumns;
};

export const defineRowData = (data, defs = false) => {
  const rowData = [];
  const columnDefs = defs || defineColumnDefs();

  data.forEach(row => {
    const newRow = {};

    columnDefs.forEach(col => {
      if (col.field === 'state') {
        newRow[col.field] = getStateLabel(row.operation.type, getObject(row, col.field));
      } else {
        newRow[col.field] = getObject(row, col.field) || 'N/A';
      }
    });

    rowData.push(newRow);
  });

  return { rowData, columnDefs };
};

export const formatFilters = filters => {
  const filtersFormated = JSON.stringify(
    filters
      .map(filter => {
        let key = filter.field.replace(/-/g, '.').split('.')[0];

        switch (key) {
          case 'delivered_data':
            key = 'delivered_at';
            break;
          case 'state':
            key = 'status';
            break;
          case 'lot_search':
            key = 'search';
            break;
          default:
        }

        const filterFormated = {
          [key]: filter.value,
        };

        if (key === 'status') {
          filterFormated.operations = filter.operations;
        }

        return filterFormated;
      })
      .reduce((a, b) => {
        a = { ...a, ...b };
        return a;
      }, {})
  );

  return filtersFormated;
};

export const getConvertedField = field => {
  switch (field) {
    case 'company-display_name':
      return 'partner';
    case 'state':
      return 'status';
    default:
      return field;
  }
};

export const getReverseFilter = field => {
  switch (field) {
    case 'partner':
      return 'company-display_name';
    case 'status':
      return 'state';
    default:
      return field;
  }
};

export const getFilters = (event, oldFilters) => {
  const { allFilters } = event.api.filterManager;
  let oldFiltersCopy = oldFilters;

  if (allFilters) {
    // eslint-disable-next-line
    let filters = Object.entries(allFilters).map(([_, value]) => {
        const { column, filterPromise } = value;
        // eslint-disable-next-line
        let { headerName: label, field } = column.colDef;
        oldFiltersCopy = oldFiltersCopy.filter(o => o.field !== field);

        field = getConvertedField(field);

        const model = filterPromise.resolution.getModel();
        const filter = { label, field };

        if (model) {
          if (model.filterType === 'date') {
            const val = {};

            if (model.dateTo) {
              val.to = model.dateTo;
            }
            if (model.dateFrom) {
              val.from = model.dateFrom;
            }

            filter.value = val.to ? val : null;

            if (!filter.value) {
              return null;
            }
            return filter;
          }

          if (model.filterType === 'state' && field === 'status') {
            const status = oldFiltersCopy.find(f => f.field === 'status');

            if (status) {
              oldFiltersCopy = oldFiltersCopy.filter(f => f.field !== 'status');
              filter.value = model.value
                ? Array.from(new Set([...status.value, ...model.value]))
                : model.value;
            } else {
              filter.value = model.value;
            }

            filter.text = model.text;
            filter.state = true;
            filter.field = field;
            filter.operations = model.operations;
            return filter;
          }

          if (model.filterType === 'state' && field === 'partner') {
            const partner = oldFiltersCopy.find(f => f.field === 'partner');

            if (partner) {
              oldFiltersCopy = oldFiltersCopy.filter(f => f.field !== 'partner');
              filter.value = model.value
                ? Array.from(new Set([...partner.value, ...model.value]))
                : model.value;
            } else {
              filter.value = model.value;
            }

            filter.text = model.text;
            filter.state = true;
            filter.field = field;
            filter.operations = model.operations;
            return filter;
          }

          return {
            value: model.value,
            field,
            label,
          };
        }

        return null;
      })
      .filter(f => !!f && !!f.value);

    filters = Array.from(
      new Set([...oldFiltersCopy, ...filters].map(f => JSON.stringify(f)))
    ).map(f => JSON.parse(f));

    return filters;
  }

  return [];
};
