// eslint-disable-next-line import/no-cycle
import api from '@/services/api';
import * as types from '@/store/types';
import { defineRowData } from '@/utils/agGrid';
import { statusOperationsExtract, safeStorage } from '@/utils';

export default {
  async [types.ACTION_ORDERS_REQUEST]({ commit, getters }, { filters, defs }) {
    const allOperations = statusOperationsExtract();

    commit(types.MUTATE_SET_PARTNER_OPERATIONS, allOperations);

    try {
      const { data: response } = await api.get(
        `/orders?limit=${getters[types.GETTER_LIMIT]}&page=${getters[types.GETTER_PAGE]}${
          filters && filters.length ? `&filters=${filters}` : ''
        }`
      );

      const result = defineRowData(response.data, defs);
      result.total = response.pagination
        ? parseInt(response.pagination.total, 10)
        : response.data.length;

      commit(types.MUTATE_ORDERS, result);
    } catch (error) {
      commit(types.MUTATE_ORDERS_ERROR, true);
      commit(types.MUTATE_ORDERS, { ...getters[types.GETTER_ORDERS], rowData: [], total: 0 });

      this.$app.$toast.error(this.$app.$t('messages.ordersFail'), {
        onClose: () => {
          commit(types.MUTATE_ORDERS_ERROR, false);
        },
      });
    }
  },
  [types.ACTION_ORDER_REQUEST]: async ({ commit }, id) => {
    const { data: response } = await api.get(`/orders/${id}`);

    commit(types.MUTATE_ORDER, response.data);
  },
  async [types.ACTION_ORDER_LOT_REQUEST]({ commit, getters }, data) {
    try {
      const { data: response } = await api.post(`/orders/search?page=1&limit=300`, data);

      const result = defineRowData(response.data, getters[types.GETTER_ORDERS].columnDefs);
      result.total = parseInt(response.data.length, 10);

      commit(types.MUTATE_ORDERS, result);
      commit(types.MUTATE_PAGE, 1);
      commit(types.MUTATE_LIMIT, 300);
    } catch (error) {
      this.$toast.error(error);
    }
  },
  [types.ACTION_ORDER_SELECT]: async ({ commit, getters }, data) => {
    const ids = getters[types.GETTER_SELECTED];

    if (!data.insert && ids.includes(data.id)) {
      const index = ids.indexOf(data.id);

      if (index > -1) {
        ids.splice(index, 1);
      }

      commit(types.MUTATE_SELECTED, ids);
      return;
    }

    if (data.insert && !ids.includes(data.id)) {
      ids.push(data.id);
      commit(types.MUTATE_SELECTED, ids);
    }
  },
  [types.ACTION_TURNOFF_PRINT_LOADING]: async ({ commit }) => {
    commit(types.MUTATE_LOADING_PRINT, false);
  },
  [types.ACTION_ORDER_LABELS_PRINT]: async ({ commit, getters }) => {
    commit(types.MUTATE_LOADING_PRINT, true);

    const ids = getters[types.GETTER_SELECTED];
    let data = [];

    const { data: response } = await api.post(`/order/print-labels`, { ids });

    if (response.success) {
      ({ data } = response);
    }

    commit(types.MUTATE_PRINT_LABELS, data);

    commit(types.MUTATE_LOADING_PRINT, false);
  },
  [types.ACTION_PARTNER_CHILDS]: async ({ commit }) => {
    const config = safeStorage.getItem('config');

    const { data: response } = await api.get('/partners/childs');

    const partnerChilds = response.data.map(partner => {
      let [, text] = partner.name.split(' - ');
      text = text ? text.replace(/_/g, ' ') : partner.name.replace(/_/g, ' ');

      return {
        value: partner.document,
        text,
      };
    });

    config.partner_childs = response.data;

    safeStorage.setItem('config', JSON.stringify(config));

    commit(types.MUTATE_PARTNER_CHILDS, partnerChilds);
  },
  [types.ACTION_CHOSEN_OPERATIONS]: async ({ commit }, operation) => {
    commit(types.MUTATE_SET_CHOSEN_OPERATIONS, operation);
  },
};
