import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import { safeStorage } from '@/utils';

import auth from './modules/auth';
import orders from './modules/orders';
import mapOrders from './modules/map_orders';

const vuexLocal = new VuexPersistence({
  storage: safeStorage,
  key: 'vuex',
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    orders,
    mapOrders,
  },
  plugins: [vuexLocal.plugin],
});
