import * as types from '@/store/types';

export default {
  [types.MUTATION_IS_AUTHENTICATED]: (state, isAuthenticated) => {
    state.isAuthenticated = isAuthenticated;
  },
  [types.MUTATION_LOGIN_SUCCESS](state, { data, remove = false }) {
    if (!remove) {
      state.token = data.access_token;
      state.expiresIn = data.expires_at;
      state.isAuthenticated = true;
    } else {
      state.token = null;
      state.expiresIn = null;
      state.isAuthenticated = false;

      this.commit(types.MUTATE_PARTNER_CHILDS);
    }
  },
  [types.MUTATION_LOADING]: (state, loading) => {
    state.loading = loading;
  },
  [types.MUTATTION_PARTNER_CONFIG]: (state, config) => {
    state.config = config;
  },
};
