import * as types from '@/store/types';

export default {
  [types.MUTATE_MAP_STATUS]: (state, selectedStatus) => {
    state.selectedStatus = selectedStatus;
  },
  [types.MUTATE_MAP_SELECTED_DATE]: (state, selectedDate) => {
    state.selectedDate = selectedDate;
  },
};
