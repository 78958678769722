<template>
  <component
    :is="`${icon}-icon`"
    :size="size"
    :fillColor="color"
    :class="{ 'mr-2': left && !right, 'ml-2': right && !left, 'icon-rotate': spin }"
  />
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: () => 28,
    },
    color: {
      type: String,
      default: () => '#fff',
    },
    right: {
      type: Boolean,
      default: () => false,
    },
    left: {
      type: Boolean,
      default: () => false,
    },
    spin: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    MenuIcon: () => import('vue-material-design-icons/Menu.vue'),
    SendIcon: () => import('vue-material-design-icons/Send.vue'),
    LoadingIcon: () => import('vue-material-design-icons/Loading.vue'),
    ViewDashboardIcon: () => import('vue-material-design-icons/ViewDashboard.vue'),
    FileSendIcon: () => import('vue-material-design-icons/FileSend.vue'),
    FileDocumentBoxSearchIcon: () => import('vue-material-design-icons/FileDocumentOutline.vue'),
    HistoryIcon: () => import('vue-material-design-icons/History.vue'),
    MapIcon: () => import('vue-material-design-icons/Map.vue'),
    MapMarkerIcon: () => import('vue-material-design-icons/MapMarker.vue'),
    AccountGroupIcon: () => import('vue-material-design-icons/AccountGroup.vue'),
    AccountIcon: () => import('vue-material-design-icons/Account.vue'),
    CurrencyUsdIcon: () => import('vue-material-design-icons/CurrencyUsd.vue'),
    ChevronRightIcon: () => import('vue-material-design-icons/ChevronRight.vue'),
    ChevronLeftIcon: () => import('vue-material-design-icons/ChevronLeft.vue'),
    ChevronDownIcon: () => import('vue-material-design-icons/ChevronDown.vue'),
    ChartBarIcon: () => import('vue-material-design-icons/ChartBar.vue'),
    CloseIcon: () => import('vue-material-design-icons/Close.vue'),
    MagnifyIcon: () => import('vue-material-design-icons/Magnify.vue'),
    FilterIcon: () => import('vue-material-design-icons/Filter.vue'),
    FilterVariantIcon: () => import('vue-material-design-icons/FilterVariant.vue'),
    FileChartIcon: () => import('vue-material-design-icons/FileChart.vue'),
    PrinterIcon: () => import('vue-material-design-icons/Printer.vue'),
    DeleteIcon: () => import('vue-material-design-icons/Delete.vue'),
    ExitToAppIcon: () => import('vue-material-design-icons/ExitToApp.vue'),
    PencilIcon: () => import('vue-material-design-icons/Pencil.vue'),
    PlusCircleOutlineIcon: () => import('vue-material-design-icons/PlusCircleOutline.vue'),
    StarOutlineIcon: () => import('vue-material-design-icons/StarOutline.vue'),
    AlertIcon: () => import('vue-material-design-icons/Alert.vue'),
    EyeIcon: () => import('vue-material-design-icons/Eye.vue'),
    InformationIcon: () => import('vue-material-design-icons/Information.vue'),
    InformationOutlineIcon: () => import('vue-material-design-icons/InformationOutline.vue'),
    CheckIcon: () => import('vue-material-design-icons/Check.vue'),
    SwapHorizontalIcon: () => import('vue-material-design-icons/SwapHorizontal.vue'),
    TruckDeliveryIcon: () => import('vue-material-design-icons/TruckDelivery.vue'),
    CancelIcon: () => import('vue-material-design-icons/Cancel.vue'),
    EmoticonCryOutlineIcon: () => import('vue-material-design-icons/EmoticonCryOutline.vue'),
    RoutesClockIcon: () => import('vue-material-design-icons/RoutesClock.vue'),
    PackageVariantClosedIcon: () => import('vue-material-design-icons/PackageVariantClosed.vue'),
    TimerIcon: () => import('vue-material-design-icons/Timer.vue'),
    CreditCardOutlineIcon: () => import('vue-material-design-icons/CreditCardOutline.vue'),
    EmailCheckOutlineIcon: () => import('vue-material-design-icons/EmailCheckOutline.vue'),
    EmailRemoveOutlineIcon: () => import('vue-material-design-icons/EmailRemoveOutline.vue'),
    CalendarMonthOutlineIcon: () => import('vue-material-design-icons/CalendarMonthOutline.vue'),
    AccountQuestionOutlineIcon: () =>
      import('vue-material-design-icons/AccountQuestionOutline.vue'),
    TicketConfirmationOutlineIcon: () =>
      import('vue-material-design-icons/TicketConfirmationOutline.vue'),
  },
};
</script>

<style lang="scss">
.icon-rotate {
  svg {
    animation: iconRotate 1s linear infinite;
  }
}

@keyframes iconRotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
