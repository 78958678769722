<template>
  <transition name="fade" mode="out-in">
    <div
      class="follow-loading-overlay d-flex justify-content-center align-items-center"
      :class="{ rounded: !noRounded }"
    >
      <icon icon="loading" :size="60" spin color="var(--primary)" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'follow-loading-overlay',
  props: {
    noRounded: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
