<template>
  <div class="text-filter p-2">
    <b-form-input
      ref="input"
      v-model="searchText"
      size="sm"
      :placeholder="$t('agGrid.localeText.searchOoo')"
    />
    <div
      class="ag-filter-apply-panel p-0 mt-4 d-flex justify-content-between align-items-center"
      ref="eButtonsPanel"
    >
      <uello-button @click="resetFilter" :text="$t('agGrid.localeText.resetFilter')" />
      <uello-button @click="setFilter" :text="$t('agGrid.localeText.applyFilter')" />
    </div>
  </div>
</template>

<script>
import { UelloButton } from '@uello/componentello';

export default {
  name: 'text-filter',
  components: {
    UelloButton,
  },
  data() {
    return {
      searchText: '',
      text: '',
      valueGetter: null,
    };
  },
  created() {
    this.valueGetter = this.params.valueGetter;
  },
  methods: {
    setFilter() {
      this.text = this.searchText;
    },
    resetFilter() {
      this.setModel(null);
    },
    isFilterActive() {
      return this.text !== null && this.text !== undefined && this.text !== '';
    },
    doesFilterPass(params) {
      return (
        !this.text ||
        this.text
          .toLowerCase()
          .split(' ')
          .every(filterWord => {
            return (
              this.valueGetter(params.node)
                .toString()
                .toLowerCase()
                .indexOf(filterWord) >= 0
            );
          })
      );
    },
    getModel() {
      return { value: this.text };
    },
    setModel(model) {
      if (model) {
        this.text = model.value;
      } else {
        this.text = '';
        this.searchText = '';
      }
    },
    afterGuiAttached() {
      this.$refs.input.focus();
    },
  },
  watch: {
    text(val, oldVal) {
      if (val !== oldVal) {
        this.params.filterChangedCallback();
      }
    },
  },
};
</script>

<style lang="scss">
.text-filter {
  width: 250px;
  div[data-v-536fa780] {
    border-radius: 0.25rem;
  }
}
</style>
