import axios from 'axios';

// eslint-disable-next-line import/no-cycle
import store from '@/store';
import * as types from '@/store/types';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: { 'Access-Control-Allow-Origin': '*' },
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // eslint-disable-next-line no-console
    console.log('status:error', [error]);

    if (
      error.response.status > 400 &&
      error.response.status < 404 &&
      window.location.pathname !== '/'
    ) {
      localStorage.clear();
      store.dispatch(types.ACTION_RESET_DATA);
      window.location.href = '/';
    }

    return Promise.reject(error);
  }
);

export default api;
