import * as types from '@/store/types';

export default {
  [types.GETTER_ORDERS]: state => state.orders,
  [types.GETTER_ORDER]: state => state.order,
  [types.GETTER_LIMIT]: state => state.limit,
  [types.GETTER_PAGE]: state => state.page,
  [types.GETTER_SELECTED]: state => state.selected,
  [types.GETTER_LOADING_PRINT]: state => state.loadingPrint,
  [types.GETTER_PRINT_LABELS]: state => state.printLabels,
  [types.GETTER_PARTNER_CHILDS]: state => state.partnerChilds,
  [types.GETTER_ORDERS_ERROR]: state => state.error,
  [types.GETTER_PARTNER_OPERATIONS]: state => state.partnerOperations,
  [types.GETTER_CHOSEN_OPERATION]: state => state.chosenOperation,
  [types.GETTER_MODAL]: state => state.recoveryModal,
  [types.GETTER_SELECTED_ALL]: state => state.allSelected,
};
