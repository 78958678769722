import Vue from 'vue';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import '../assets/scss/_custom.scss';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '../assets/scss/_transition-animations.scss';
import '../assets/scss/_helpers.scss';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
