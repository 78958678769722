<template>
  <div class="backdrop">
    <div class="simpleModal">
      <div class="close"><a href="#" @click.prevent.stop="closeModal">X</a></div>
      <div>
        <svg
          width="216"
          height="216"
          viewBox="0 0 216 216"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M55.5745 161.195C24.8815 133.376 22.1669 86.3671 49.5112 56.1974C76.8555 26.0276 113.333 28.8407 154.597 51.9401C195.861 75.0395 212.241 136.359 160.66 156.937C109.08 177.515 86.2675 189.013 55.5745 161.195Z"
            fill="#D9D9D9"
            fill-opacity="0.4"
          />
          <rect
            x="50.2394"
            y="46.8895"
            width="82.9218"
            height="119.085"
            rx="6"
            transform="rotate(-7.34845 50.2394 46.8895)"
            fill="white"
            stroke="#0B4F6C"
            stroke-width="4"
          />
          <rect
            x="68.1499"
            y="33"
            width="98.85"
            height="132.4"
            rx="6"
            fill="white"
            stroke="#0B4F6C"
            stroke-width="4"
          />
          <rect x="105.2" y="51.3605" width="48.4" height="3.85" rx="1.925" fill="#0B4F6C" />
          <circle cx="85.5217" cy="53.3967" r="3.78125" fill="#0B4F6C" />
          <rect x="105.2" y="76.3751" width="48.4" height="3.85" rx="1.925" fill="#0B4F6C" />
          <circle cx="85.5217" cy="78.4111" r="3.78125" fill="#0B4F6C" />
          <rect x="105.2" y="101.39" width="48.4" height="3.85" rx="1.925" fill="#0B4F6C" />
          <circle cx="85.5217" cy="103.425" r="3.78125" fill="#0B4F6C" />
          <rect x="105.2" y="126.404" width="48.4" height="3.85" rx="1.925" fill="#0B4F6C" />
          <circle cx="85.5217" cy="128.44" r="3.78125" fill="#0B4F6C" />
        </svg>
        <svg
          v-if="type === 'success'"
          class="icon"
          width="58"
          height="58"
          viewBox="0 0 58 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_7_120)">
            <path
              d="M28.65 47.3C41.1592 47.3 51.3 37.1592 51.3 24.65C51.3 12.1407 41.1592 2 28.65 2C16.1407 2 6 12.1407 6 24.65C6 37.1592 16.1407 47.3 28.65 47.3Z"
              fill="#FBFBFF"
              stroke="#53CB3A"
              stroke-width="4"
              stroke-miterlimit="10"
            />
            <path
              d="M25.1007 29.5205L26.5149 30.9298L27.9266 29.518L38.4718 18.9729L39.3099 19.811L26.9309 32.2111L26.9297 32.2123L26.5034 32.6386L26.0669 32.2076L18.9302 25.0513L19.7716 24.21L25.1007 29.5205Z"
              fill="#53CB3A"
              stroke="#53CB3A"
              stroke-width="4"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_7_120"
              x="0"
              y="0"
              width="57.3"
              height="57.3"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7_120" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_7_120"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
        <svg
          v-if="type === 'error'"
          class="icon"
          width="57"
          height="57"
          viewBox="0 0 57 57"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_14_497)">
            <path
              d="M28.4998 2C40.926 2 50.9996 12.0736 50.9996 24.4998C50.9996 36.9263 40.926 47 28.4998 47C16.0733 47 6 36.9263 6 24.4998C6 12.0737 16.0733 2 28.4998 2Z"
              fill="white"
              stroke="#E63C4F"
              stroke-width="4"
            />
            <path
              d="M16.3086 33.4092L19.604 36.7046L24.7337 31.5742L40.6908 15.6175L37.3686 12.295L26.5417 23.1223L16.3086 33.4092Z"
              fill="#E63C4F"
            />
            <path
              d="M40.6908 33.4092L37.3953 36.7046L32.2656 31.5742L16.3086 15.6175L19.6311 12.295L30.4577 23.1223L40.6908 33.4092Z"
              fill="#E63C4F"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_14_497"
              x="0"
              y="0"
              width="56.9995"
              height="57"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14_497" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_14_497"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div v-html="text"></div>
      <div v-if="model === 'confirm'" class="buttons">
        <button class="buttonSecondary" @click.prevent.stop="closeModal">cancelar</button>
        <button class="buttonPrimary" @click="confirmRequest">confirmar</button>
      </div>

      <div v-if="model === 'success' || model === 'error'" class="buttons">
        <button class="buttonPrimary" @click.prevent.stop="closeModal">ok</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
    model: {
      type: String,
      default: 'confirm',
    },
  },
  name: 'SimpleModal',

  methods: {
    closeModal() {
      this.$store.commit('@orders/MUTATE_MODAL', {
        confirmed: false,
        visible: false,
        loading: false,
      });
    },
    confirmRequest() {
      this.$store.commit('@orders/MUTATE_MODAL', {
        confirmed: true,
        visible: false,
        loading: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  position: absolute;
  margin-left: -80px;
  margin-top: 20px;
}
.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  // transform: translate(-50%, -50%);
  z-index: 1000000003;
  background: #00000087;
  backdrop-filter: blur(2px);
}
.simpleModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000000004;
  background: #fff;
  color: #757575;
  text-align: center;
  box-shadow: 0px 0px 10px #0000004a;
  border-radius: 8px;
  padding: 40px;
}
.close {
  text-align: right;
  margin-top: -20px;
}
.close a {
  text-align: right;
  // margin-top: -40px;
  // margin-right: -20px;
  color: #757575 !important;
  font-weight: normal;
  font-size: 22px;
}
.buttons {
  padding-top: 20px;
}
.buttonPrimary {
  width: 87px;
  height: 32px;
  background: #0b4f6c;
  border: 1px solid #0b4f6c;
  border-radius: 4px;
  color: #fff;
  margin: 4px;
}
.buttonSecondary {
  width: 87px;
  height: 32px;
  border: 1px solid #0b4f6c;
  border-radius: 4px;
  color: #0b4f6c;
  background: #ffffff;
  margin: 4px;
}
</style>
