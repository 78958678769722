import * as types from '@/store/types';

export default {
  [types.MUTATE_ORDERS](state, orders) {
    state.orders = orders;
  },
  [types.MUTATE_LIMIT]: (state, limit) => {
    state.limit = limit;
  },
  [types.MUTATE_PAGE]: (state, page) => {
    state.page = page;
  },
  [types.MUTATE_SELECTED]: (state, ids) => {
    state.selected = ids;
  },
  [types.MUTATE_LOADING_PRINT]: (state, status) => {
    state.loadingPrint = status;
  },
  [types.MUTATE_PRINT_LABELS]: (state, labels) => {
    state.printLabels = labels;
  },
  [types.MUTATE_ORDER]: (state, order) => {
    state.order = order;
  },
  [types.MUTATE_PARTNER_CHILDS]: (state, partnerChilds = []) => {
    state.partnerChilds = partnerChilds;
  },
  [types.MUTATE_ORDERS_ERROR]: (state, error) => {
    state.error = error;
  },
  [types.MUTATE_SET_PARTNER_OPERATIONS]: (state, operations) => {
    state.partnerOperations = operations;
  },
  [types.MUTATE_SET_CHOSEN_OPERATIONS]: (state, operation) => {
    state.chosenOperation = operation;
  },
  [types.MUTATE_MODAL]: (state, payload) => {
    state.recoveryModal = {
      ...state.recoveryModal,
      ...payload,
    };
  },
  [types.MUTATE_ALL_ORDER_SELECTED]: (state, payload) => {
    state.allSelected = payload;
  },
  [types.MUTATE_SELECTED_ALL]: (state, payload) => {
    state.selected = payload;
  },
};
