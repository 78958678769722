import { parseISO, format } from 'date-fns';
import { uuid } from 'uuidv4';
/* eslint-disable no-restricted-globals  */
/* eslint-disable no-new-func  */
/* eslint-disable func-names */

import { safeStorage } from './storage';

export * from './storage';
export * from './agGrid';

const colors = [
  '#b61827',
  '#b4004e',
  '#790e8b',
  '#4d2c91',
  '#001064',
  '#0077c2',
  '#0086c3',
  '#0095a8',
  '#00766c',
  '#005005',
  '#338a3e',
  '#6b9b37',
  '#a0af22',
  '#c9bc1f',
  '#c77800',
  '#c63f17',
  '#5f4339',
];

export function randomColor() {
  const randomIndex = Math.round(Math.random() * (colors.length - 1) - 0);

  return colors[randomIndex];
}

export function fakeGeocode() {
  const maxLongitude = -46.4483757;
  const minLongitude = -46.9358304;
  const maxLatitude = -23.4502108;
  const minLatitude = -23.7306371;

  const latitude = Number((Math.random() * (maxLatitude - minLatitude) + minLatitude).toFixed(7));

  const longitude = Number(
    (Math.random() * (maxLongitude - minLongitude) + minLongitude).toFixed(7)
  );

  return [longitude, latitude];
}

export function getDatePicker() {
  function DatePicker() {}
  DatePicker.prototype.init = function(params) {
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;
    $(this.eInput).datepicker({ dateFormat: 'dd/mm/yyyy' });
  };

  DatePicker.prototype.getGui = function() {
    return this.eInput;
  };

  DatePicker.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
  };

  DatePicker.prototype.getValue = function() {
    return this.eInput.value;
  };

  DatePicker.prototype.destroy = function() {};
  DatePicker.prototype.isPopup = function() {
    return false;
  };

  return DatePicker;
}

export function getStateStyle(operationType, state) {
  const { statuses } = safeStorage.getItem('config');

  const status = statuses[operationType][state] ? statuses[operationType][state] : null;
  return status;
}

export function capitalize(value) {
  if (!value) {
    return '';
  }

  const arr = value.toString().split(' ');
  const capitalizedArray = [];

  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalizedArray.push(capitalized);
  });

  return capitalizedArray.join(' ');
}

export function getObject(obj, path) {
  const formatedPath = path
    .split('-')
    .map(param => (!isNaN(parseInt(param, 10)) ? `[${param}]` : param))
    .join('.')
    .replace(/(\.\[)/g, '[');

  let value = new Function('_', `return _.${formatedPath}`)(obj);

  if (
    formatedPath === 'created_at' ||
    (formatedPath === 'agreement_date' && value) ||
    (formatedPath === 'first_agreement_date' && value) ||
    (formatedPath === 'delivered_data.date' && value)
  ) {
    if (value !== 'N/A') {
      const [date, time] = value.split(' ');
      value = `${format(parseISO(date), 'dd/MM/yyyy')} ${time || ''}`;
    }
  }

  return value;
}

export function dateFormat(date) {
  if (date) {
    const [dated, hour] = date.split(' ');
    return `${format(parseISO(dated), 'dd/MM/yyyy')} ${hour}`;
  }

  return date;
}

export function monetaryFormat(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
}

export function numberFormat(value) {
  return new Intl.NumberFormat('pt-BR').format(value);
}

export function stripTags(text) {
  return text.replace(/(<([^>]+)>)/gi, '');
}

export function maxLengthString(str, length) {
  const newStr = str.split('').splice(0, length);
  newStr.push('...');

  return newStr.join('');
}

export function nameInitials(name) {
  const nameSplited = name.split(' ');
  const [firstName, lastName] = nameSplited.filter(
    (_, index, arr) => index === 0 || index === arr.length - 1
  );
  return firstName.charAt(0).toUpperCase() + (lastName ? lastName.charAt(0).toUpperCase() : '');
}

export function getGeoLocation(successCallback, errorCallback) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => successCallback([coords.longitude, coords.latitude]),
      errorCallback
    );
  }
}

export function getPartner() {
  const config = safeStorage.getItem('config');

  if (config) {
    const [partner] = config.operations;
    return partner.partner;
  }

  return 'Partner';
}

export function getUserInfo() {
  return safeStorage.getItem('user');
}

export function haveStop() {
  const { operations } = safeStorage.getItem('config');
  const has = !!operations.find(op => op.type === 'spot');
  return has;
}

export function statusOperationsExtract() {
  const { operations, statuses } = safeStorage.getItem('config');
  const ops = Array.from(new Set(operations.map(op => op.type)));

  const allOperations = ops.reduce((accumulator, operation) => {
    const mappedStatuses = Object.entries(statuses[operation]).map(([key, status]) => ({
      ...status,
      value: key,
      id: uuid(),
      operation,
    }));

    return [...accumulator, ...mappedStatuses];
  }, []);

  return allOperations;
}

export function getStatusLabel(statusCode, operation) {
  const statuses = safeStorage.getItem('config').statuses[operation];

  return statuses[statusCode] || statuses.outros;
}

export function getOperationType(selectedOperation) {
  let operationType = null;
  const { has_childs, operations, partner_childs } = safeStorage.getItem('config');

  if (operations.length === 1 && !has_childs) {
    return operations[0].type;
  }

  let partner = operations.find(op => op.document === selectedOperation.cnpj);

  if (!partner && has_childs) {
    partner = partner_childs.find(op => op.document === selectedOperation.cnpj);
  }

  if (partner) {
    operationType = partner.operations.find(op => op.id === selectedOperation.operation);
    operationType = operationType ? operationType.type : null;
  }

  return operationType;
}

export function createArrayOfUniques(array) {
  return Array.from(
    new Set(
      array.map(item => {
        if (typeof item === 'object') {
          return JSON.stringify(item);
        }

        return item;
      })
    )
  ).map(item => {
    if (typeof item === 'string') {
      return JSON.parse(item);
    }

    return item;
  });
}
